
/* New Design Phase 2 */
:root{
    --bank-ng:#d5d5d5;
}
.bank-wrapped{
    background: var(--secondary-color);
    /* min-height: 90vh; */
    width: 100%;
    padding: 2em;
    height: 100%;
  overflow: auto;
  }
.bank-table table{
    border-collapse: initial;
    border-spacing: 0 .8em;
}
.bank-table tr th{
    color: var(--grey-color);
    font-weight: 500;
    font-size: 0.9em;
}
.bank-table tr td{
   background-color: var(--white-grey-color); 
   font-weight: 600;
   font-size: 0.85em;
   color: var(--black-text);
}
.bank-table tr th,.bank-table tr td{
    vertical-align: middle;
    padding: 12px;
    border: 0;
}

.bank-table tr th:last-child,.bank-table tr td:last-child{
    text-align:center;
}
.bank-table-frame{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;
}
.bank-titles h2 {
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.4;
    color: var(--black-text);
    margin-bottom: 0;
}
.td-bank-details{
    display: flex;
    align-items: center;
    gap: 1em;
}
.td-bank-details-icons{
    background-color: var(--grey-color);
    border-radius: 10px;
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.view-canvas-modal.offcanvas {
    --bs-offcanvas-width: 500px;
}
.td-bank-date{
    font-size: 0.85em;
    color: var(--grey-color);
    font-weight: 500;
}
.bank-ac-lable{
    font-size: 0.8em;
    font-weight: 400;
    color: var(--grey-color);
    line-height: 1.6;  
    margin-bottom: 0.6em;
}
.bank-ac-value{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;
}
.bank-user-card{
    display: grid;
    grid-template-columns: 50px auto;
    align-items: center;
    gap: 1em;
    background: linear-gradient(269.26deg, #FFFFFF 2.37%, #F9F9F6 82.53%);
    border: 1px solid #F4F5F5;
    border-radius: 10px;
    padding: 1em;
    margin-bottom: 1em;
}
.bank-view-account-card{
    margin-bottom: 1em;
}
.bank-use-type{
    font-size: 0.8em;
    font-weight: 400;
    color: var(--grey-color);
    line-height: 1.6;  
}
.bank-use-name{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;
}

.bank-other-details{
    background-color: var(--bg-grey);
    padding: 1em;
    border-radius: 10px;
}
.view-tabs{
    gap: 1em;
    margin-bottom: 1.5em!important;
}
.view-tabs .nav-link.active{
    background-color: var(--primary-color)!important;
}
.view-tabs .nav-link{
    background-color: var(--secondary-color)!important;
    font-weight: 500;
    color: var(--black-text)!important;
    font-size: 0.85em!important;
    border-radius: 30px!important;
}
.bank-card-pay{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;
    display: flex;
    align-items: center;
    gap: 1em;
    /* justify-content: end; */
}
.add-bank-wrapped{
    background: var(--secondary-color);
    /* min-height: 90vh; */
    height: 100%;
    width: 100%;
    overflow:auto;
}
.add-bank-wrapped::-webkit-scrollbar{
    width: 0;
}
.create-recipient-form-back{
    padding: 2em;
    border-bottom: 1px solid var(--bg-grey);
    
}

.add-bank-full-frame{
    /* display: flex; */
    /* gap: 2em; */
    /* height: calc(100% - 12.2%); */
    /* padding: 2em; */
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding:0.5em 0.2em;
}
.add-bank-full-frame::-webkit-scrollbar{
    width: 03px;
}
.add-bank-full-frame::-webkit-scrollbar-thumb{
    background-color: var(--primary-color);
}
.add-bank-forms{
    flex: 1 auto;
}
.add-bank-step-frame{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2em;
    align-items: start;
    background-color: var(--bg-grey);
    height: 100%;
    padding: 2em;
    width: 30%;
}
.add-bank-forms{
    width: 70%;
    padding: 2em;
}
.add-bank-card{
    /* text-align: center; */
    display: grid    ;
        grid-template-columns: 60px auto;
        align-items: start;
        gap: 1em;
        overflow: hidden;
        height: 100%;
        width: 100%;
    }

.add-bank-step-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.add-bank-card.active .add-bank-icons{
    border: 1px solid var(--primary-color);

}
.add-bank-card.completed .add-bank-icons{
    background-color: var(--primary-color);

}
.add-bank-card.completed .add-bank-icons::after{
    background-color: var(--primary-color);

}
.add-bank-icons{
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: var(--bank-ng);
    /* margin: 0 auto; */
    position: relative;
    border: 1px solid transparent;
}
.add-bank-card:last-child .add-bank-icons::after{
    display: none;
}
.add-bank-icons::after{
    content: '';
    position: absolute;
    height: 100vh;
    width: 3px;
    background-color: var(--bank-ng);
    left: 24px;
    top: 90px;
    z-index: 99;
}
.add-bank-info{
}
.add-bank-step{
    font-size: 0.85em;
    font-weight: 500;
    color: var(--black-text);
    line-height: 1.6;
}
.add-bank-step-info{
    font-size: 0.9em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.6;
}
.add-bank-step-details{
    font-size: 0.9em;
    font-weight: 400;
    color: var(--grey-color);
    line-height: 1.6;
}
.bank-btn-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}
.bank-action-btn{
    --_bgcolor:var(--bgcolor,var(--primary-color));
    background-color: var(--_bgcolor) !important;
    color: var(--link-color) !important;
    padding: 5px !important;
    border: 0 !important;
    /* font-weight: 600 !important; */
    width: 2.4em!important;
    height: 2.4em!important;
    /* font-size: 0.85em !important; */
}
.view{
    --bgcolor:var(--copy-color);
}
.block{
    --bgcolor:var(--bg-grey);
}
.delete{
    --bgcolor:   rgb(255 59 62 / 10%);
}
.active{
    --bgcolor:#e3e3e3;
}

/* Account Details View Modal style  */
.account-details-modal .modal-content {
    padding: 2em;
}
.account-details-modal .modal-header {
    padding: 0 !important;
    border-bottom: 1px solid transparent !important;
    position: absolute;
    right: 1.5em;
    z-index: 99;
}
.account-details-modal .modal-body {
    padding: 0 !important;
}
.account-details-titles h2 {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 0.3em;
    color: var(--tertiary-color);
    /* text-align: center; */
}
.account-details-top{
    padding: 1em 0;
    border-bottom: 1px solid #efefef;
    margin-bottom: 1em;
}
.account-details-top p{
    font-size: .9em;
    font-weight: 400;
    margin-bottom: 0;
    color:#979ba2;
}
.account-details-top p span{
    color: var(--tertiary-color)!important;
}
.account-details-name{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.account-details-name p{
    font-size: .9em;
    font-weight: 400;
    margin-bottom: 0;
    color:#979ba2;
}
.account-details-name p span{
    color: var(--tertiary-color)!important;
    font-weight: 500;

}
.account-crypto-badge{
    background-color: #efefef;
    padding: 0.5em 1.2em;
    border-radius: 12px;
    font-size: .9em;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--tertiary-color)!important;
    text-align: center;
}
.info-label-card{
    display: grid;
    grid-template-columns: 35px auto;
    align-items: center;
}
.account-details-middle{
    padding-bottom: 1.5em;
}
.account-details-middle .account-info-card:last-child{
    margin-bottom: 0;
}
.account-info-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
}
.info-label-badge{
    background-color: #00B06F36;
    border: 1px solid #00B06F;
    padding: 0.5em 1.2em;
    border-radius: 12px;
    font-size: .9em;
    font-weight: 600;
    margin-bottom: 0;
    color: #00B06F!important;
    text-align: center;
}
.info-label-card{
    font-size: .9em;
    font-weight: 400;
    margin-bottom: 0;
    color: #979ba2;
}
.info-label-data{
    display: grid;
    grid-template-columns:auto 35px;
    align-items: center;  
    gap: 1em;
    font-size: .9em;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--tertiary-color);
}
.account-details-bottom{
    border-radius: 12px;
    padding: 1em;
    border: 1px solid #efefef;
}
.info-label-point{
    font-size: .9em;
    font-weight: 400;
    margin-bottom: 0;
    color: #979ba2;
}
.info-label-info{
    font-size: .9em;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--tertiary-color); 
}
.account-details-wrapped{
    width: 100%;
    height: 100%;
    background-color: #f7f7f7;
    padding: 2em;
}
.account-details-frame{
    background: var(--secondary-color);
    padding: 1.5em;
    border-radius: 10px;
}
.token-frame-bank{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin: 1em 0;
}