.efi-exchange-swap-wrapped .efi-swap-items {
    background-color: transparent !important;
    border-radius: 0 !important;
    color: #666666 !important;
    font-size: 0.85em;
}

.efi-exchange-swap-wrapped .efi-swap-items.active {
    /* border-bottom: 2px solid var(--secondary-color); */
    background: var(--secondary-color)!important;
    border-radius: 10px!important;
}
.efi-exchange-swap-wrapped .nav-item:first-child .efi-swap-items.active{
    color: #008826!important;

}
.efi-exchange-swap-wrapped .nav-item:last-child .efi-swap-items.active{
    color: red!important;

}
.efi-exchange-swap-full-frame {
    /* border: 1px solid #ffffff47;
    backdrop-filter: blur(22px); */
    /* border-radius: 10px; */
    /* border: 1px solid rgba(255, 255, 255, 0.28); */
    /* background:#010101; */
    backdrop-filter: blur(22px);
    /* padding: 3em 2em; */
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    margin-top: 1em;
}
.efi-exchange-swap-wrapped{
    padding: 1.5em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #efeeee82;
    border-radius: 12px;
}
.feature-box-frame{
    padding-top: 4em;
}
.efi-exchange-swap-wrapped .nav-pills,.efi-exchange-swap-wrapped .tab-content{
    width: 100%;
}
.default-swap-btn {
    background-color: var(--primary-color) !important;
    padding: 0.6em 2em !important;
    text-align: center !important;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -o-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 10px !important;
    color: var(--secondary-color) !important;
    display: flex !important;
    align-items: center;
    gap: 0.5em;
    justify-content: center;
    border: 1px solid var(--primary-color) !important;
    font-size: 1em !important;
    font-weight: 600 !important;
    width: 100%;
}

.efi-exchange-fields-card {
    /* padding: 2.5em; */
    /* background: #d8f3ff14;
    backdrop-filter: blur(10px); */
    border-radius: 10px;
    background: #FFFFFF99;
    backdrop-filter: blur(62px);
}

.efi-fields-label {
    font-size: 1.1em;
    font-weight: 600;
    text-transform: capitalize;
    color: #010101;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
    margin-bottom: 1.5em;
}

.efi-fields-label-enter {
    font-size: 0.9em;
    font-weight: 600;
    color: #010101;
    margin-bottom: 1.5em;
}

.efi-exchange-fields-card {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    /* flex-direction: column; */
    /* gap: 1em; */
    height: 100%;
    width: 100%;
    /* flex: 1; */
}

.efi-exchange-fields-card .form-control {
    background-color: transparent !important;
    color: #7D7D7D !important;
    font-size: 1.5em;
    font-weight: 700;
    border: 1px solid #00000026!important;
    padding-left:16px !important;
    height: 45px;
}

.efi-exchange-fields-card .efi-receive-text {
    color: #7D7D7D !important;
    font-size: 1.5em;
    font-weight: 700;
    border: 0 !important;

}

.efi-exchange-fields-card .form-control:focus {
    box-shadow: unset !important;
}

.efi-exchange-input-wrapped {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    display: flex;
    gap: 1em;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.efi-exchange-input-wrapped .efi-exchange-fields-card:nth-child(1){
    z-index: 1050;

}
.efi-exchange-input-wrapped .efi-exchange-fields-card:nth-child(2){
    z-index: 99;
}
.efi-info-frame p{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.efi-swap-exchange-info p {
    color: #010101;
    font-size: 0.9em;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
    line-height: 1.8;
}

.efi-swap-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    z-index: 9999;
}

/* .efi-swap-icons::after {
    content: ' ';
    position: absolute;
    background: #ffffff47;
    width: 282px;
    height: 1px;
    left: 50px;
    top: 43px;
    display: inline-block;
}

.efi-swap-icons::before {
    content: ' ';
    position: absolute;
    background: #ffffff47;
    width: 282px;
    height: 1px;
    right: 50px;
    top: 43px;
    display: inline-block;
} */
/* 
.efi-exchange-fields-card::before{
    content: ' ';
    position: absolute;
    background: #ffffff47;
    width: 100%;
    height: 1px;
    top: 50.5%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

.efi-exchange-fields-type,
.efi-exchange-fields-enter {
    padding: 1.5em;
}

.efi-exchange-fields-type {
    /* border-bottom: 1px solid var(--secondary-color); */
    background: #f8f9fa;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.swap-icon {
    z-index: 999;
}

.swap-icon.crypto-icon {
    width: 1.5em;
    height: 1.5em;
}

.css-s8q6nw-singleValue {
    color: #fff !important;
}
.efi-exchange-swap-wrapped .nav-item{
    background: #FFFFFF47;
    padding: 0.7em;
}
.efi-exchange-swap-wrapped .nav-item:first-child{
    border-top-left-radius: 10px!important;
    border-bottom-left-radius: 10px!important;
}
.efi-exchange-swap-wrapped .nav-item:last-child{
    border-top-right-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
}
.efi-info-frame{
    border: 1px dashed #0000003B;
    padding: 0.7em;
    border-radius: 10px;
    background: #0000000A;
}

.buy-flow-product-sec{
    background: #FFFFFF82;
    padding: 1.5em;
    border-radius: 10px;
}
.buy-flow-product-sec .efi-exchange-swap-full-frame{
    margin-top: 0!important;
}

.marketing-sec .home-banner-sec::before {
    background: none !important;
}

.marketing-sec .market-banner-image-sec {
    background: #efeeee82;
    padding: 1.5em;
    border-radius: 12px;
}

.about-market-icon{
    height: 4em;
    width: auto;
    object-fit: contain;
}

.market-why-choose-image{
    height: auto;
    width: 85%;
    object-fit: contain;
}

.marketing-sec .footer-item-box{
    padding-top: 0 !important;
}