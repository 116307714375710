.auth {
    background: #F9F9F9;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
}

.auth_fav_img {
    max-width: 5em;
    height: auto;
    object-fit: contain;
}

.auth_box {
    /* display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%; */
}

.auth_nav,
.auth_content {
    border: 1px solid var(--border-color);
    border-radius: 10px;
    background: var(--secondary-color);
}

.auth_nav,
.auth_content {
    height: 100%;
}

.auth_nav .nav-pills {
    flex-direction: column;
    height: 100%;
}

.auth_nav .nav-pills .navbar-brand,
.auth_nav .nav-pills .nav-item {
    flex: 1 0;
}

.auth_nav .nav-pills .navbar-brand .auth_fav,
.auth_nav .nav-pills .nav-item .nav-link {
    height: 100%;
}

.auth_nav .nav-link {
    background-color: transparent !important;
    border-radius: 0 !important;
    opacity: 0.2;
    border-left: 2px solid transparent;
}

.auth_nav .nav-link span {
    font-size: 0.9em;
    font-weight: 500;
    color: var(--tertiary-color);
}

.auth_nav .nav-link.active {
    background-color: var(--bg-hover) !important;
    border-left: 2px solid var(--primary-color);
    opacity: 1;
}

.auth_avater {
    background: var(--tertiary-color);
    transform: scale(1.11);
    height: 100%;
}

.login-box-1 {
    padding: 4em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.auth-login-avater-logo {
    margin-bottom: 2em;
}

/* .auth-login-avater-logo .logo {
    filter: brightness(0.7);
} */

.auth_deposit_selected {}

.auth-deposit-img-sec{
  margin-bottom: 2em;
}

.auth_deposit_selected .auth_deposit_type span {
  font-weight: 600 !important;
  color: var(--primary-color) !important;
}

.auth_deposit_selected .auth_deposit_type {
  font-size: 0.95em;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
  color: var(--quaternary-color);
  background: var(--overlay-bg);
  padding: 0.5em 1.2em;
  border-radius: 25px;
  width: fit-content;
}


.auth-login-avater {
    background: url("../../../public/img/phase_1/auth/new-athu.png") no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.avater-frame-auth {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.auth-login-form-frame {
    background: #F9F9F9;
}

.auth-login-avater-info h2 {
    font-size: 1.3em;
    font-weight: 600;
    margin-bottom: 0.5em;
    color: var(--tertiary-color);
}

.auth-login-avater-info p {
    font-size: 0.95em;
    font-weight: 400;
    margin-bottom: 0em;
    color: var(--text-color);
    line-height: 1.8;
}

.page-error-img {
    width: 20em;
    height: auto;
    object-fit: contain;
}
.auth_deposit-sec {
    width: 3em;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
}

.auth_deposit_qr {
    width: 8em;
    height: 8em;
    object-fit: contain;
    margin: 0 auto;
    border: 1px solid var(--border-color);
    border-radius: 10px;
}

.auth_deposit_space {
    position: relative;
}

.auth_deposit_space::after {
    content: ' ';
    background: var(--border-color);
    width: 100%;
    height: 1px;
    width: 100%;
    top: 17px;
    position: absolute;
    display: inline-block;
    z-index: 0;
}

.auth_deposit_space p {
    padding: 0.4em;
    font-size: 0.8em;
    font-weight: 400;
    color: var(--tertiary-color);
    margin-bottom: 0;
    text-align: center;
    background: var(--secondary-color);
    width: fit-content;
    position: relative;
    z-index: 99;
}

.auth_deposit_code {
    display: grid;
    grid-template-columns: 550px auto;
    gap: 0.5em;
    margin-bottom: 1em;
}

.auth_buy_code {
    display: grid;
    grid-template-columns:auto 65px;
    gap: 0.5em;
}

.auth_deposit_code_card {
    border: 1px solid var(--border-color);
    padding: 0.7em;
    border-radius: 10px;
    height: 45px;
    display:flex;
    align-item:center;
}

.auth_deposit_code_card span {
    font-size: 0.85em;
    font-weight: 500;
    color: var(--tertiary-color);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.auth_deposit_action :where(.secondary, .primary) {
    width: 100% !important;
}
.auth_2fa_qr {
    width: 10em;
    height: 10em;
    object-fit: contain;
    margin: 0 auto;
    border: 1px solid var(--border-color);
    border-radius: 10px;
}
.auth_2fa_code {
    display: grid;
    grid-template-columns: 370px auto;
    gap: 0.5em;
}

.auth_2fa_code_card {
    border: 1px solid var(--border-color);
    padding: 0.7em;
    border-radius: 10px;
}

.auth_2fa_code_card span {
    font-size: 1em;
    font-weight: 400;
    color: var(--tertiary-color);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}