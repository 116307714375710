
/* New Design Phase 2 */

.transation-wrapped{
    background: var(--secondary-color);
    /* min-height: 90vh; */
    width: 100%;
    padding: 2em;
    height: 100%;
    overflow:auto;
  }
  .transation-wrapped::-webkit-scrollbar{
    width: 0;
  }
.transaction-tabs{
    gap: 2em;
    border-bottom: 1px solid var(--try-grey);
}
  .transaction-tabs .nav-link.active{
    border-bottom: 2px solid var(--primary-color);
}
  .transaction-tabs .nav-link{
    background-color: unset!important;
    font-weight: 500;
    color: var(--black-text);
    font-size: 0.9em;
    border-radius: 0!important;
    border-bottom: 2px solid transparent;
    padding: 1em 0;
}
.transaction-table{

}

.transaction-table table{
    border-collapse: initial;
    border-spacing: 0 .8em;
}
.transaction-table tr th{
    color: var(--grey-color);
    font-weight: 500;
    font-size: 0.9em;
}
.transaction-table tr th:last-child, .transaction-table tr td:last-child{
    text-align:center;
}
.transaction-table tr td{
   background-color: var(--white-grey-color); 
   font-weight: 600;
   font-size: 0.85em;
   color: var(--black-text);
}
.transaction-table tr th,.transaction-table tr td{
    vertical-align: middle;
    padding: 12px;
    border: 0;
}
.transation-titles{
    padding-bottom: 1em;
}
.transation-titles h2 {
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.4;
    color: var(--black-text);
    margin-bottom: 0;
}

.view-canvas-modal{
    background: var(--secondary-color)!important;
    background-image: unset!important;
}
.view-canvas-modal .offcanvas-title{
    font-size:1em!important;
    font-weight: 600;
    color: var(--black-text);
}
.view-transaction-amt-card{
    padding-bottom: 1em;
    border-bottom: 1px solid #EBEBEB;
    margin-bottom: 1em;
}
.view-label{
    font-size: 0.8em;
    font-weight: 400;
    color: var(--grey-color);
    margin-bottom: 0.5em;
    line-height: 1.6;
}
.view-value{
    font-size: 0.9em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;
    word-break: break-word;
}
.view-status-card{
    padding-bottom: 1em;
}
.view-status-label{
    font-size: 0.8em;
    font-weight: 400;
    color: var(--grey-color);
    margin-bottom: 0.5em;
    line-height: 1.6;
}
.view-status-badges{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    gap: 0.5em;
}
.view-date-card{
    padding-bottom: 1.5em;
}
.view-date-label{
    font-size: 0.8em;
    font-weight: 400;
    color: var(--grey-color);
    margin-bottom: 0.5em;
    line-height: 1.6;
}
.view-date-badges{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;
}
.copy-view-btn{
    background-color:var(--copy-color)!important ;
    width: 2.5em;
    height: 2.5em;
    display: flex!important;
    align-items: center;
    justify-content: center;
    border-radius: 50%!important;
    border: 0!important;
    padding: 10px!important;
}
.view-other-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding-bottom: 1em;
}
.view-other-label{
    font-size: 0.8em;
    font-weight: 400;
    color: var(--grey-color);
    line-height: 1.6;  
}
.view-other-pay{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;
    display: grid;
    grid-template-columns:auto 30px ;
    align-items: center;
    gap: 1em;
    justify-content: end;
}
.bank-info-card{
    padding-bottom: 1em;
}
.bank-label{
    font-size: 0.8em;
    font-weight: 400;
    color: var(--grey-color);
    line-height: 1.6;  
    margin-bottom: 0.6em;
}
.bank-value{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;
}
.view-other-value{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;
    text-align: right;
}
.view-other-details-card{
    background-color: var(--bg-grey);
    padding: 1em;
    border-radius: 10px;
}
.view-canvas-modal.offcanvas {
    --bs-offcanvas-width: 500px;
}
.view-tabs{
    gap: 1em;
    margin-bottom: 1.5em!important;
}
.view-tabs .nav-link.active{
    background-color: var(--primary-color)!important;
}
.view-tabs .nav-link{
    background-color: var(--secondary-color)!important;
    font-weight: 500;
    color: var(--black-text);
    font-size: 0.85em!important;
    border-radius: 30px!important;
}