
.main-wrapper {
    min-height: 90vh;
}

/* header  */

.header-nav-center {
    /* background: #f3e2c3; */
    /* box-shadow:2px -7px 10px rgba(0, 0, 0, 0.5); */
    /* background-color: #f2f0ea; */
    position: relative;
    z-index: 9;
    padding: 0em 0;
}

.header-nav-center .navbar-expand-lg .navbar-nav .nav-link {
    color: var(--tertiary-color);
    font-weight: 500;
    font-size: 0.95em;
    margin: 0 1em;
    border-bottom: 3px solid transparent;
    padding: 0;
    padding-bottom: 0em;
}

.header-nav-center .navbar-expand-lg .navbar-nav .nav-link:last-child {
    margin-right: 0;
}

.header-nav-center .navbar-nav .nav-link.active {
    position: relative;
}

.header-nav-center .navbar-nav .nav-link.active::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--primary-color);
    bottom: -23px;
    left: 0;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.home-banner-sec {
    /* background-image: url("../img/gif/efi-exchange-pro-banner.gif"); */
    background-image: url('../../../public/img/phase2/banner-shatter.svg');
    min-height: calc(100vh - 70px);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    width: 100%;
    display: block;
    z-index: 0;
    position: relative;
    /* background: rgb(243 226 195); */
    padding-top: 2em;
}
.home-banner-sec{
    /* position: relative; */
}
.home-banner-sec::before{
    content: '';
    position: absolute;
    background: #fefcf8;
    width: 100%;
    height: 32%;
    right: 0;
    bottom: 0;
    clip-path: polygon(0 200px,100% 0,100% 101%,0 100%);
}


.home-banner-btn-box {
    /* grid-template-columns: repeat(2, 1fr);
    display: grid; */
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2em;
    row-gap: 1em;
}

.banner-new-content {
    position: relative;
    padding-right: 1em;

}

.banner-new-content h5 {
    font-size: 1em;
    font-weight: 600;
    color: #50535e;
    line-height: 1.5;
}

.vertical-center-1 {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    min-height: calc(100vh - 98px);
}

.banner-new-content h1 {
    font-size: 3em;
    color: var(--tertiary-color);
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 0.5em;
    word-break: break-word;
}

.banner-new-content h1 span {
    color: var(--tertiary-color);
    font-weight: 800;
    /* padding-left: 0.2em; */
}

.banner-new-content p {
    line-height: 1.8;
    color: var(--tertiary-color);
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
}

.banner-img {
    max-width: 22em;
    margin-top: 2em;
}


/* Banner Crypto batch frame start */
.banner-batch-frame {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
}

.banner-crypto-link-info h3 {
    font-size: 0.95em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.4em;
}

.banner-crypto-link-info p {
    font-size: 0.85em;
    font-weight: 400;
    color: var(--quaternary-color);
    margin-bottom: 0em;
    line-height: 1.8;
}

.banner-crypto-link {
    /* background-image:radial-gradient(circle farthest-corner at 3.7% 49.8%, rgb(255 234 149 / 28%) 21.9%, rgb(255 252 243) 52.9%); */
    border-radius: 10px;
    /* background: radial-gradient(rgb(249 194 3 / 40%), #fff); */
    background: linear-gradient(rgb(249 194 3 / 40%), #fff);;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: space-between;
}

.banner-crypto-link-action {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.crypto-link {
    background-color: var(--secondary-color);
    padding: 0.6em 2em !important;
    text-align: center;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    color: var(--tertiary-color) !important;
    display: flex !important;
    align-items: center;
    gap: 0.5em;
    border: 1px solid var(--secondary-color);
    font-size: 0.9em !important;
    font-weight: 600 !important;
    width: fit-content;
}

.banner-crypto-icons {
    width: 2.5em;
    height: 2.5em;
    object-fit: contain;
}

/* Banner Crypto batch frame end  */

.hamburger-menu {
    /* max-width: 2em; */
    height: 1.5em;
}


.new-landing-page-sec {
    font-family: "Red Hat Text", sans-serif !important;
    background-color: #fefcf8;
}

/* banner  */
.exchange-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 70px);
    /* background-image: url(../img/gif/efi-exchange-pro-banner.gif); */
    /* background: linear-gradient(150deg, rgba(249,182,33,0.5) 0%, rgba(255,246,179,1) 44%, rgba(191,90,21,0.5) 99%); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgb(243 226 195);
}

.banner-content h1 {
    font-size: 3.5em;
    color: var(--tertiary-color);
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 0.5em;
}

.banner-content h1 span {
    color: var(--primary-color);
    /* padding-left: 0.2em; */
}

.banner-content p {
    line-height: 1.8;
    color: var(--quaternary-color);
    margin-bottom: 0;
    font-size: 1em;
    font-weight: 400;
}

.banner-img {
    max-width: 22em;
    margin-top: 2em;
}

.new-footer-sec {
    /* background: linear-gradient(90deg, #161234 0%, #030613 102.51%); */
    /* background: linear-gradient(89.75deg, #010104 0%, #130603 102.51%); */
    background: url("../../../public/img/gif/footer-bg.png") no-repeat #000;
    background-size: cover;
    background-position: left center;
    padding-bottom: 2em;
    transition: all 20s ease-in-out;

    animation: left-right 20s linear infinite;
}

@keyframes left-right {
    0% {
        background-position: 0 0;
    }

    20% {
        background-position: -100px 0;
    }

    40% {
        background-position: -200px 0;
    }

    60% {
        background-position: -150px 0;
    }

    80% {
        background-position: -100px 0;
    }

    100% {
        background-position: 0px 0;
    }
}

.footer-copyright-sec{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ffffff52;
    margin-top: 2em;
    padding-top: 2em;
}

.footer-copyright-sec p{
    margin-bottom: 0;
}
.footer-sec {
    /* background-color: var(--primary-color); */
    /* background: linear-gradient(90deg, #161234 0%, #030613 102.51%); */
    background: linear-gradient(89.75deg, #010104 0%, #130603 102.51%);
    padding: 4em 0;
}

.footer-about-top-box {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 1rem;
}

.footer-about-card h3 {
    font-size: 1em;
    font-weight: 600;
    color: var(--secondary-color);
    margin-bottom: 1em;
}

.footer-about-list li a {
    color: var(--secondary-color);
    font-weight: 400;
    font-size: 0.9em;
}

.footer-about-list li {
    padding-bottom: 1em;
}

.footer-about-list li:last-child {
    padding-bottom: 0;
}

.footer-about-list {
    margin-bottom: 0;
}

.footer-about-top-box {
    margin-bottom: 2em;
}

.footer-about-bottom-box {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.footer-copyright-sec p {
    color: var(--secondary-color);
    font-weight: 400;
    font-size: 0.9em;
    margin-bottom: 0;
    line-height: 1.8;
}

.footer-about-box {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* .footer-social-link-sec {
  padding-top: 2em;
} */

.footer-social-link {
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: center;
    margin-bottom: 0;
}


.best-crypto-wrapped {
    /* background: url("../img/gif/partical-shetter.jpeg") no-repeat 0 0; */
    /* background-size: cover; */
}

.best-crypto-step-title {
    margin-bottom: 3em;
}

.best-crypto-step-title h2 span {
    color: var(--tertiary-color);
    font-weight: 800;
}

.best-crypto-step-title h2 {
    font-size: 2.5em;
    color: var(--tertiary-color);
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0.5em;
}

.best-crypto-step-title p {
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.8;
    color: var(--tertiary-color);
}

.best-crypto-step-card {
    padding: 2em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: -5px 5px -5px -5px;
}

.best-card-full-frame {
    height: 100%;
}


.outer-border {
    border: 1px solid #e0e1e4;
    border-radius: 8px;
}

.fade-color-one {
    background: #ffe6df;
}

.fade-color-two {
    background: #cff4ee;
}

.fade-color-three {
    background: #d1e4ff;
}

.fade-color-four {
    background: #d1ffd3;
}

.fade-color-five {
    background: #fad1ff;
}

.fade-color-six {
    background: #fcffd1;
}
.num-bold {
    display: block;
    position: absolute;
    top: -5px;
    right: 20px;
    text-align: right;
}
.best-card-head {
    display: grid;
    grid-template-columns: 60px auto;
    align-items: center;
}

.best-crypto-step-card :where(h4, span) {
    color: var(--tertiary-color);
}

.best-crypto-step-card h4 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1.5;
}

.best-crypto-step-card p {
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.8;
    color: var(--tertiary-color);
}

.best-crypto-step-card .num-bold span {
    font-size: 95px;
    line-height: 1;
    color: rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    font-weight: 700;
}

/* Exchange Platform style Start  (landing page) */
.exchange-platform-frame {}

.exchange-platfrom-info-card {
    --_background-img: var(--background-img, #fff);
    padding: 2em;
    border-radius: 12px;
    border: 1px solid #F2F2F2;
    background: var(--_background-img) no-repeat top;
    background-size: cover;
    height: 100%;
}

.grdaient-blur-one {
    --background-img: url('/public/img/gradient-blur-color.png');
}

.grdaient-blur-two {
    --background-img: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.dev/svgjs" viewBox="0 0 800 450" opacity="0.42"><defs><filter id="bbblurry-filter" x="-100%" y="-100%" width="400%" height="400%" filterUnits="objectBoundingBox" primitiveUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feGaussianBlur stdDeviation="130" x="0%" y="0%" width="100%" height="100%" in="SourceGraphic" edgeMode="none" result="blur"></feGaussianBlur></filter></defs><g filter="url(%23bbblurry-filter)"><ellipse rx="214" ry="217" cx="174.17566750266337" cy="154.3531133478338" fill="hsla(39, 100%, 76%, 1.00)"></ellipse><ellipse rx="214" ry="217" cx="590.7979125976562" cy="369.6405029296875" fill="hsla(182, 100%, 80%, 1.00)"></ellipse><ellipse rx="214" ry="217" cx="383.0767184170809" cy="89.97349756414238" fill="hsla(216, 100%, 92%, 1.00)"></ellipse><ellipse rx="214" ry="217" cx="202.6350167014382" cy="346.4081198952415" fill="hsla(153, 100%, 92%, 1.00)"></ellipse></g></svg>');
}

.grdaient-blur-color {
    --background-img: #F8F8F8;
}

.info-batch-card {

    padding: 0.6em 1.5em;
    display: grid;
    grid-template-columns: 60px auto;
    align-items: center;
    background: var(--light-color);
    border-radius: 40px;
}

.batch-card-icons {
    width: 2.5em;
    height: 2.5em;
    object-fit: contain;
}

.info-batch-card-info {
    font-size: 0.85em;
    font-weight: 500;
    color: var(--tertiary-color);
    /* padding: 0 1em; */
}

.exchange-platfrom-info-batch-frame {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    flex-wrap: wrap;
    /* padding: 0 2em; */
}

.exchange-platfrom-info h3,
.exchange-platfrom-info-frame-info h3,
.echange-platfrom-vertical-info h3 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.5em;
    color: var(--tertiary-color);
    line-height: 1.4;
}

.exchange-platfrom-info p,
.exchange-platfrom-info-frame-info p,
.echange-platfrom-vertical-info p {
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 1.8;
    color: var(--tertiary-color);
}

.exchange-platfrom-info-frame {
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 3em;
}

.platfrom-card-icons {
    width: 30em;
    height: auto;
    object-fit: contain;
}

.item-grid-layout_2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2em;
    margin-bottom: 1.2em;
}
.item-grid-layout_2:last-child{
    margin-bottom: 0em;

}

.echange-platfrom-vertical-frame {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
}

.vertical-card-icons {
    width: 6em;
    height: 6em;
    object-fit: contain;
}

.vertical-card-icons-two {
    width: 7em;
    height: auto;
    object-fit: contain;
}

.vertical-card-icons-three {
    width: 12em;
    height: 5em;
    object-fit: contain;
}

.exchange-platfrom-primary-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2em;
    height: 100%;
}

/* Exchange Platform style end  */


.how-its-work-box {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 2em;
    align-items: start;
    background: #fff;
    border-radius: 10px;
    padding: 2em;
}

.how-its-work-icon-sec {
    margin-bottom: 1em;
}

.how-its-work-card {
    border-right: 1px solid #eaeaea;
    padding-right: 1.5em;
}

.how-its-work-card:last-child {
    border-right: 0 !important;
    padding-right: 0 !important;
}


.how-its-work-icon {
    height: 2.5em;
    object-fit: contain;
}

.how-its-work-info h4 {
    font-size: 1em;
    font-weight: 600;
    color: var(--tertiary-color);
    line-height: 1.5;
}

.how-its-work-info p {
    line-height: 1.8;
    color: var(--quaternary-color);
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
}

.how-its-work-card:hover .learn-more-btn svg {
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */

    animation: zoom-left 1s ease infinite alternate-reverse;
    /* animation: move-up6 2s ease-in infinite ; */
}

@keyframes zoom-left {
    100% {
        transform: scale(1.5) translateX(10px);
    }
}

.how-its-work-card:hover .how-its-work-icon {
    animation: icon-bounce 0.8s 1;
}

@keyframes icon-bounce {

    0%,
    100%,
    20%,
    50%,
    80% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -o-transform: translateY(-10px);
        transform: translateY(-10px);
    }

    60% {
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -o-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

.learn-more-btn svg {
    transition: all 2s ease-in;
}

.learn-more-btn {
    width: 100%;
    background-color: transparent;
    text-align: center;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 2s ease-in-out;
    position: relative;
    overflow: hidden;
    color: #E89900 !important;
    text-decoration: underline !important;
    display: flex !important;
    align-items: center;
    gap: 0.5em;
    border: 0px solid var(--secondary-color);
    font-size: 0.9em;
    font-weight: 600;
}


.learn-more-btn-sec {
    display: flex;
    margin-top: 1em;
}


/* Growth Of Exchange CSS */

.growth-of-exchange-sec.bg-privacy-protection {
    background-color: #fbf9f4;
}

.growth-of-exchange-sec {
    background-color: #fbfbf7;
}

.growth-of-exchange-info h2 {
    font-size: 2.5em;
    color: var(--tertiary-color);
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 0.5em;
    word-break: break-word;
}

.growth-of-exchange-info h2 span {
    color: var(--tertiary-color);
    font-weight: 800;
}

.growth-of-exchange-info p {
    line-height: 1.8;
    color: var(--quaternary-color);
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
}

.growth-of-exchange-list-info h4 {
    font-size: 1em;
    font-weight: 600;
    color: var(--tertiary-color);
    line-height: 1.5;
}

.growth-of-exchange-list-info p {
    line-height: 1.8;
    color: var(--quaternary-color);
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
}

.growth-of-exchange-list-box {
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    gap: 1em;
    margin-top: 2em;
}

.supported-currency-list-box {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 1em;
    margin-top: 2em;
}

.growth-of-exchange-list-card {
    grid-template-columns: 50px auto;
    display: grid;
}

/* Demo of Product CSS */

.demo-of-product-box {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 1em;
}

.demo-of-product-card {
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    background: var(--secondary-color);
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
}

.demo-of-product-info h4 {
    font-size: 1em;
    font-weight: 600;
    color: var(--tertiary-color);
    line-height: 1.5;
}

.demo-of-product-info h4 span {
    color: var(--primary-color);
}

.demo-of-product-info p {
    line-height: 1.8;
    color: #7d7d7d;
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
}

.demo-product-btn-sec {
    margin-top: 1.5em;
}

.demo-of-product-icon-sec {
    display: flex;
    align-items: center;
    justify-content: center;
}

.demo-of-product-icon {
    max-width: 7em;
}

.demo-product-btn-sec-1 {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 1.5em;
}

.demo-of-product-priority-card {
    border-radius: 10px;
    padding: 2em;
    /* background: linear-gradient(90deg, #161234 0%, #030613 102.51%); */
    background-color: #000116;
}

.demo-of-product-priority-info h4 {
    font-size: 1em;
    font-weight: 600;
    color: var(--secondary-color);
    line-height: 1.5;
}

.demo-of-product-priority-info h4 span {
    color: var(--primary-color);
}

.demo-of-product-priority-info p {
    line-height: 1.8;
    color: #7d7d7d;
    margin-bottom: 1em;
    font-size: 0.9em;
    font-weight: 400;
}

/* Contact Us CSS */

.contact-us-sec {
    background: url("../../../public/img/landing/contact-us/contact-us-bg.png") #f9f2e5;
    background-repeat: no-repeat;
    background-position: left;
}

.contact-us-form-box {
    box-shadow: 0px 1.162px 5.811px 0px rgba(21, 8, 43, 0.06);
    border-radius: 10px;
    background-color: var(--secondary-color);
    padding: 2em;
}

.contact-us-form-box h3 {
    font-size: 2em;
    color: var(--tertiary-color);
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 0.5em;
}

.contact-us-form-box p {
    line-height: 1.8;
    color: #7d7d7d;
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
}

.contact-us-form .form-control {
    border: 1px solid rgba(171, 171, 171, 0.1) !important;
    background: rgba(125, 125, 125, 0.02);
    height: 45px;
    border-radius: 10px;
}

.contact-us-form .form-control:focus {
    box-shadow: none;
    background: rgba(125, 125, 125, 0.02);
    outline: none;
}

.contact-us-form textarea.form-control {
    height: auto;
}

.contact-us-form .form-label {
    font-size: 0.8em;
    font-weight: 500;
    color: var(--tertiary-color);
}

.contact-us-form-box .form-label span {
    color: red;
}

.contact-us-submit-btn-sec {
    margin-top: 1.5em;
}

/* FAQ CSS */

.faq-sec .faq-accordion-1 .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid rgba(225, 225, 225, 0.5);
    color: var(--tertiary-color);
}

.faq-sec .faq-accordion-1 .accordion-header {
    border-bottom: 1px solid rgba(225, 225, 225, 0.5);
}

.faq-sec .faq-accordion-1 .accordion-item {
    border: none;
    /* box-shadow: 0 3px 10px 0 rgb(0 0 0 / 7%); */
    background-color: transparent;
}

.faq-sec .faq-accordion-1 .accordion-item .accordion-button {
    border-bottom: 0;
    font-size: 0.5em;
    background-color: transparent;
    font-weight: 600;
    color: var(--tertiary-color);
    padding: 1.3em;
}

.faq-sec .faq-accordion-1 .accordion-item .accordion-button:focus {
    box-shadow: none;
}

.faq-sec .faq-accordion-1 .accordion-item .accordion-body {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--quaternary-color);
    /* background-color: #f5f5fc; */
    /* border: 1px solid rgba(225, 225, 225, 0.5); */
    background: transparent;
    border-radius: 10px;
    line-height: 1.8;
    margin: 0;
    /* margin-top: 1em; */
}

.faq-sec .faq-accordion-1 .accordion-button:not(.collapsed)::after {
    background-image: url(../../../public/img/landing/faq/minus.svg);
    transform: unset;
}

.faq-sec .faq-accordion-1 .accordion-button::after {
    background-image: url(../../../public/img/landing/faq/plus.svg);
}

.faq-sec .faq-accordion-1.accordion {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
}

/* Why Choose Us CSS */
.why-choose-us-sec {
    background: url("../../../public/img/gif/why-choose-us-bg.jpeg") no-repeat 0 0;
    background-size: cover;
    position: relative;
}

/* .why-choose-us-sec::before{
  content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #000, transparent) !important;
} */

/* .why-choose-us-item {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    margin-top: 4em;
    gap: 1em;
    justify-content: center;
    align-items: center;
} */

.why-choose-us-icon-sec {
    margin-bottom: 1em;
}

.why-choose-us-card {
    /* border: 1px solid var(--secondary-color);
  background: linear-gradient(312deg, rgba(255, 255, 255, 0.70) 6.1%, rgba(255, 255, 255, 0.00) 81.6%);
  border-radius: 10px; */
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.28);
    background: #010101;
    -webkit-backdrop-filter: blur(22px);
    backdrop-filter: blur(22px);
    padding: 1.5em;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.why-choose-us-info h4 {
    font-size: 1em;
    font-weight: 600;
    color: var(--secondary-color);
    line-height: 1.5;
}

.why-choose-us-info p {
    line-height: 1.8;
    color: #7d7d7d;
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
}

.why-choose-us-header-sec h2 {
    font-size: 2.5em;
    color: var(--secondary-color);
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 0.5em;
}

.why-choose-us-header-sec p {
    line-height: 1.8;
    color: #7d7d7d;
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
}
