
/* Onboard Section Style start  */

.onboarding-layout {
    background: #F9F9F9;
    height: calc(100vh - 10px);
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    overflow: hidden;

}
.onboard {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: var(--secondary-color);
}

.onboard_avater {
    background: var(--tertiary-color);
}

.onboard_info {
    padding: 2em;
    background: var(--secondary-color);
}

.onboard_info h2 {
    font-size: 1.3em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 0.5em;
    text-align: center;
}

.onboard_info p {
    font-size: 0.9em;
    font-weight: 400;
    text-align: center;
    color: var(--quaternary-color);
    margin-bottom: 0em;
    line-height: 1.6;
}

.onboard_info_action {
    gap: 1.5em;
}

.onboard_form_titles h2 {
    font-size: 1.2em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 0;
}

.onboard_form_titles {
    margin-bottom: 1.5em;
    display: flex !important;
    justify-content: space-between;
    gap: 0.5em;
}

/* New Onboarding style  */
.board-wrapped {
    background: var(--secondary-color);
    border-radius: 10px;
    padding: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
}

.board-wrapped-info h3 {

    font-size: 1.4em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 0.3em;
    text-align: center;
}

.board-wrapped-info p {
    line-height: 1.8;
    color: var(--tertiary-color);
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
    text-align: center;
}

.board-avater-sec {
    width: 23em;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
}

.board-wrapped-action {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.borad-accordian-card {
    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 1.5em;

}

.board-accordian-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.board-accordian-body {
    margin-top: 1em;
}

.board-accordian-title h3 {
    font-size: 1em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 0;
}

.board-accordian-action {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1em;
}
.onboard_modal .modal-content {
    padding: 2em;
}
.onboard_modal .modal-header {
    padding: 0 !important;
    border-bottom: 1px solid transparent !important;
    position: absolute;
    right: 1.5em;
    z-index: 99;
}
.onboard_modal .modal-body{
    padding: 0 !important;
}
.onboard_modal .modal-body {
    padding: 0 !important;
}

.board-accordian-body {
    display: none;
}

.board-accordian-body.active {
    display: block;
}

.borad-accordian-wrapped .borad-accordian-card {
    margin-bottom: 1em;
}

.borad-accordian-wrapped .borad-accordian-card:last-child {
    margin-bottom: 0em;
}
