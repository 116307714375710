h1 {
  color: red;
}


.wallet-top-frame {
  background: #F9F9F9;
}

.wallet-full-transaction-frame,
.wallet-currency-list-wrapped {
  background: var(--secondary-color);
}

.wallet-full-transaction-frame {
  /* height: 100vh; */
}

.wallet-section-frame {
  padding: 2em 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 1.5em;
}

.wallet-details-card {
  display: grid;
  grid-template-columns: 55px auto;
  align-items: center;
  background: var(--secondary-color);
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 1.5em;
}

.wallet-details-btn-card {

  /* background: var(--secondary-color);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 2em;  */
}

.wallet-details-info h3 {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--tertiary-color);
  /* line-height: 1.5; */
  margin-bottom: 0.3em;
}

.wallet-details-info p {
  font-size: 1em;
  font-weight: 600;
  color: var(--tertiary-color);
  line-height: 1.5;
  margin-bottom: 0;
}

.wallet-modals-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.wallet-modals-area button {
  background-color: var(--primary-color) !important;
  /* background: linear-gradient(281deg, #FFCD00 3.44%, #F90 100%); */
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  box-shadow: 0 15px 30px 0 rgb(5 16 44 / 15%);
  color: var(--secondary-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  border: none;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border: 0 !important;
  width: 100%;
}

.wallet-full-transaction-top {
  margin-top: 0;
  padding: 0 0 2em 0;
  display: flex;
  align-items: center;
  justify-content: end;
  /* border-bottom: 1px solid #F9F9F9;
  margin-bottom: 2em; */
}

.text-danger {
  font-size: 0.8em;
}

.fillert-drop {
  /* flex: 1 1; */
}

.wallet-full-transaction-sort {
  display: flex;
  align-items: center;
  gap: 1em;
}

.wallet-full-transaction-sort .exchange-form .form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border-color: #ced4da;
}

.wallet-full-transaction-sort span {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--tertiary-color);
}

.wallet-full-transaction-title h2 {
  font-size: 1.1em;
  font-weight: 500;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.wallet-full-transaction-table {
  padding: 2em 0;
}

.wallet-full-transaction-table .table {
  overflow: hidden;
}





.paid {
  color: #27C124;
  padding: 0.5em 1em;
  display: inline-block;
  border-radius: 20px;
  background: rgba(39, 193, 36, 0.20);
  text-align: center;
  margin-bottom: 0;
  font-size: .9em;
  font-weight: 500;
}

.processing {
  color: #000;
  padding: 0.5em 1em;
  display: inline-block;
  border-radius: 20px;
  background: #fff;
  text-align: center;
  margin-bottom: 0;
  font-size: .9em;
  font-weight: 500;
  border: 1px solid #ccc;
}

.wallet-full-transaction-table tbody tr td {
  padding: 1em;
  vertical-align: middle;
  /* text-align: center; */
  /* font-size: 0.8em; */
  /* font-weight: 600; */
}


.withdraw-history-sec {
  /* margin-top: 4em; */
}

.withdraw-history-btn .cancel-btn {
  color: #000;
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: center;
  border: 0px;
  display: inline-block;
  padding: 0.5em 1em !important;
  width: 120px;
  border-radius: 50px;
  background-color: rgba(79, 70, 229, .1) !important;
}

.withdraw-history-btn .cancel-btn:hover {
  color: #dc3545;
}

.withdraw-history-btn .completed-btn {
  color: #27C124;
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: center;
  border: 1px solid#27C124 !important;
  background-color: transparent !important;
  display: inline-block;
  padding: 0.5em 1em !important;
  width: 120px;
  border-radius: 0.375rem;
}

.withdraw-history-btn .completed-btn:hover {
  color: #27C124;
}

.withdraw-history-from-to-sec h6 {
  color: rgba(11, 11, 18, 0.87);
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.withdraw-history-from-to-sec {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: center;
}

.withdraw-history-form-to-box p {
  margin-bottom: 0;
}

.withdraw-history-from-to-sec h6 {
  display: flex;
  align-items: center;
  gap: 0.4em;
}

.withdrawwallet .modal-title,
.edit-profile .modal-title {
  font-size: 1em;
  font-weight: 600;
  color: var(--tertiary-color);
}

.withdrawwallet .exchange-form,
.wallet-full-transaction-sort .exchange-form,
.profile-two-auth-input .exchange-form,
.edit-profile .exchange-form {
  margin-top: 0;
}

.withdraw-btns {
  padding: 1em 0;
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: end;
}

.withdrawwallet button.save,
.edit-profile button.save,
.swap-modal-new .default-btn.swap {
  background-color: var(--primary-color) !important;
  /* background: linear-gradient(281deg, #FFCD00 3.44%, #F90 100%); */
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  /* box-shadow: 0 15px 30px 0 rgb(5 16 44 / 15%); */
  color: var(--secondary-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  border: none;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border: 0 !important;
  /* width: 100%; */
}

.withdrawwallet button.cancel,
.edit-profile button.cancel,
.swap-modal-new .default-btn.cancel {
  /* background-color: var(--primary-color) !important; */
  background: #FCF4E3;
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  /* box-shadow: 0 15px 30px 0 rgb(5 16 44 / 15%); */
  color: var(--tertiary-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  border: none;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border: 0 !important;
  /* width: 100%; */
}

.withdraw-other-sec {
  /* display: flex; */
  align-items: center;
  gap: 1em;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 1em;
}

.withdraw-details-wrapped {
  padding: 1em;
  border: 1px solid #E89900;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;

}

.withdraw-details-top h5 {
  font-size: 1em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.withdraw-details-top p {
  margin-bottom: 0;
  font-size: 0.8em;
  font-weight: 500;
  color: var(--tertiary-color);
}

.withdraw-details-middle p {
  margin-bottom: 0;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--tertiary-color);
}

.withdraw-add-other-account-area {
  display: flex;
  align-items: center;
  gap: 1em;
  /* justify-content: center; */
}

.withdraw-add-other-account-area p {
  color: #4495D3;
  margin-bottom: 0;
  font-size: 0.9em;
  font-weight: 500;

}

.withdraw-add-other-account-wrap {
  padding: 1em;
  border: 1px dashed #4495D3;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.add_bank_img{
  max-width:20em;
  height:auto;
  margin: 0 auto;
  margin-bottom: 1.5em;
}
.wallet-full-transaction-table .nav-item {
  border-bottom: 1px solid #efefef;
}

.header-search .form-control {
  height: 45px;
}

.wallet-full-transaction-table .nav-tabs .nav-link.active {
  /* background: linear-gradient(281deg, #FFCD00 3.44%, #F90 100%); */
  background-color: var(--primary-color);
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  /* -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px; */
  /* border-radius: 50px; */
  /* box-shadow: 0 15px 30px 0 rgb(5 16 44 / 15%); */
  color: var(--secondary-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  border: none;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border: 0 !important;
  height: 45px;
}

.wallet-full-transaction-table .nav-tabs .nav-link {
  background: #f9f9f9;
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  /* -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px; */
  /* border-radius: 50px; */
  /* box-shadow: 0 15px 30px 0 rgb(5 16 44 / 15%); */
  color: var(--tertiary-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  border: none;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border: 0 !important;
  height: 45px;
}

.histroy-id-frame {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: center;
}

.histroy-id-frame .copy-btn,
.admin-crypto-details-sec .copy-btn {
  border: 0 !important;
  padding: 0.5em;
  background-color: transparent !important;
  /* background-color: rgb(59 186 156 / 20%)!important; */
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.withdrawwallet .modal-header button {
  border: 0 !important;
  padding: 0.5em;
  background-color: transparent !important;
  /* background-color: rgb(59 186 156 / 20%)!important; */
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}

.wallet-full-transaction-table .nav-tabs {
  border-bottom: 0 !important;
  gap: 1em;
}

.withdraw-history-btn {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: center;
}

.wallet-currency-list-wrapped {
  margin-top: 1.2em;
  display: inline-block;
  border-radius: 16px;
  width: 100%;
  margin-bottom:1.2em;
}

.wallet-currency-title h2 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0em;
}

.wallet-currency-list-area {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 1em;
}

.wallet-currency-list-card {
  padding: 1.5em;
  border-radius: 10px;
  display: flex;
  /* flex-direction: column; */
  gap: 0.5em;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  justify-content: space-between;
  align-items: center;
  background: url("../../../public/img/phase_1/wallet/wallet_back.png") no-repeat center;
  background-size: cover;
}

.currency_list_link {
  width: 2em;
  height: 2em;
  background: var(--border-color);
  border-radius: 50%;
  display: inline-block;
  transition: all 0.7s ease;
}

.currency_list_link:hover {
  background: var(--primary-color);
}

.wallet_currency_info p {
  font-size: 0.8em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0em;
}

.wallet-currency-list_left {
  display: flex;
  gap: 2em;
  flex-direction: column;
}

.wallet-currency-list-info h3 {
  font-size: 0.95em;
  font-weight: 500;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.wallet_currency_info h3 {
  font-size: 0.9em;
  font-weight: 700;
  color: var(--tertiary-color);
  margin-bottom: 0.5em;
}

.wallet-currency-list-icon {
  width: 2em;
  height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wallet-currency-list-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wallet-currency-list-bottom {
  display: flex;
  align-items: center;
}

.wallet-currency-list-bottom .list-link {
  color: var(--primary-color);
  font-size: 0.9em;
  font-weight: 500;
  /* transition: all 0.5 ease;
  transition: all 2s; */
  display: flex;
  align-items: center;
  gap: 0.3em;
}

.wallet-tabs-content .nav-item .wallet_tab_btn, .wallet-full-transaction-table .nav-item .wallet_tab_btn,.deposit_fiat_nav_list .wallet_tab_btn,.transaction-basic-tab-wrap .nav-item .view_tab_btn{
  font-size: 0.9em;
  color: var(--tertiary-color);
  background-color: transparent !important;
  opacity: 0.5;
  border-radius: 0 !important;
  padding: 1em 0em;
}

.wallet-tabs-content .nav-item .wallet_tab_btn.active, .wallet-full-transaction-table .nav-item .wallet_tab_btn.active,.deposit_fiat_nav_list .wallet_tab_btn.active,.transaction-basic-tab-wrap .nav-item .view_tab_btn.active{
  opacity: 1;
  border-bottom: 2px solid var(--primary-color);
}

.wallet-tabs-content .nav-pills, .wallet-full-transaction-table .nav-pills ,.transaction-basic-tab-wrap .nav-pills{
  gap: 2em;
  box-shadow: 0px 1px 0px 0px #00000012;
}
.deposit_fiat_nav_list .nav-pills{
  justify-content: center;
  gap: 2em;
  box-shadow: 0px 1px 0px 0px #00000012;
}

.wallet_recent_table_titles h3 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--tertiary-color);
}

.profile-full-wrapped {
  padding: 2em 0;
}

.profile-details-wrapped,
.profile-settings-wrapped,
.profile-password-setting-card {
  /* background: var(--secondary-color);
  padding: 2em;
  border-radius: 16px; */
  box-shadow: 0px 0px 250px 0px rgba(6, 31, 17, 0.07);
  border-radius: 20px;
  background-color: var(--secondary-color);
  border: 1px solid #f8e6e647;
  padding: 2em;
}

.profile-avatar-card {
  margin-bottom: 1em;
}

.profile-avatar-card,
.profile-avatar-info-card {
  /* border: 1px solid #dee2e6; */
  /* padding: 1em ; */
  /* border-radius: 16px; */
}

.profile-avatar-card-top {
  padding-bottom: 1em;
  border-bottom: 1px solid #dee2e6;
}

.profile-avatar-card-top h3,
.profile-two-auth-top h3,
.profile-password-setting-info h3 {
  font-size: 1em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0.4em;
}

.profile-avatar-card-top p,
.profile-two-auth-top p,
.profile-password-setting-info p {
  font-size: 0.9em;
  font-weight: 500;
  color: #757575;
  margin-bottom: 0;
}

.profile-avatar-card-Bottom {
  padding-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.profile-avatar-image-area {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile-avatar-image-frame {
  width: 7em;
  height: 7em;

}

.profile-avatar-image-frame img {
  object-fit: cover;
}

.upload-file-btn {
  background: linear-gradient(89deg, rgba(255, 205, 0, 0.10) 0.53%, rgba(255, 153, 0, 0.10) 96.47%);
  background-color: unset !important;
  color: #E89900 !important;
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  border: none;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border: 0 !important;
}

.finger-print-btn {
  background-color: rgba(51, 255, 0, 0.10) !important;
  color: #1AA080 !important;
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  border: none;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border: 1px solid #1AA080 !important;
}

.profile-full-info-card p:first-child {
  font-size: 0.8em;
  color: #757575;
  font-weight: 500;
  margin-bottom: 0.3em;
}

.profile-full-info-card p:last-child {
  font-size: 0.9em;
  color: var(--tertiary-color);
  font-weight: 500;
  margin-bottom: 0;
}

.profile-full-info-card {
  margin-bottom: 1em;
  word-break: break-all;
}

.profile-avatar-form .profile-full-info-card:last-child {
  margin-bottom: 0;
}

.profile-avatar-info-card {
  display: flex;
  align-items: start;
  justify-content: space-between;
  /* padding: 1em 0; */
  background: var(--secondary-color);
}

.profile-avatar-form,
.profile-avatar-edit-area {
  /* padding: 1em 0; */
}

.profile-avatar-edit-area button.edit-btn-profile,
.profile-password-set-btn .password-btn {
  background-color: #FCF4E3 !important;
  color: #000 !important;
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  border: none;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border: 0 !important;
}

.profile-two-auth-top {
  display: flex;
  align-items: start;
  margin-bottom: 1em;
  justify-content: space-between;
}

.profile-two-authentication-card .exchange-form button {
  /* background: linear-gradient(281deg, #FFCD00 3.44%, #F90 100%); */
  background-color: var(--primary-color);
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  /* -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px; */
  border-radius: 50px;
  /* box-shadow: 0 15px 30px 0 rgb(5 16 44 / 15%); */
  color: var(--secondary-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  border: none;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border: 0 !important;
}

.profile-password-set-btn .delete-btn {
  background: #FFF2F2;
  padding: 0.6em 2em !important;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  /* -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
  -ms-border-radius: 50px; */
  border-radius: 50px;
  /* box-shadow: 0 15px 30px 0 rgb(5 16 44 / 15%); */
  color: #FF5F5F !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
  border: none;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border: 0 !important;
}

.profile-password-setting-card {
  display: flex;
  gap: 1em;
  align-items: start;
  margin-top: 1em;
}

.profile-password-setting-info {
  flex: 1;
}

.profile-two-auth-switch .pretty.p-switch input:checked~.state:before {
  border-color: #4EB565;
  background: #4EB565;
}

.profile-two-auth-switch .pretty.p-switch input:checked~.state label:after {
  background-color: #fff !important;
}

.swap-modal-new .modal-title {
  font-size: 1em;
  font-weight: 600;
}

.swap-modal-new .modal-footer {
  border-top: none;
}

.swap-modal-new .swap-token-input {
  width: 100%;
  height: auto;
  border: 1px solid var(--border-color);
  padding: 1em;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: 2em;
  font-size: 0.9em;
  font-weight: 600;
}


.swap-modal-new .swap-token-input .recieve-amount h3 {
  font-size: 0.95em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0.5em;
  word-break: break-word;

}

.swap-modal-new .swap-token-input .recieve-amount p {
  font-size: 0.9em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
}

.swap-modal-new .swap-token-input .aed-currenc {
  justify-content: end;
  display: flex;
  align-items: end;
}

.swap-modal-new .swap-token-input .aed-currency .aed-currency-svg {
  background: #F6F6F6;
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  border-radius: 10px;
  gap: 0.5em;
}

.aed-currency-svg .swap-img {
  width: 2em;
  height: 2em;
  object-fit: contain;
}

.aed-currency {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.swap-modal-new .swap-token-input .aed-currency .inr {
  background: none;
  border: 1px solid #EBEBEB;

}

.swap-modal-new .swap-token-input .aed-currency .aed-currency-svg p {
  font-size: 0.9em;
  font-weight: 600;
  color: #0E0E0E;
  margin-bottom: 0;
}

 .balance {
  font-size: 0.9em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
}

 .balance span {
  color: var(--tertiary-color);
  font-weight: 700;
}

.swap-modal-new .swap-token-total {
  margin-top: 1em;
  width: 100%;
  height: 50%;
  padding: 20px 10px;
  border-radius: 20px;
}

.swap-arrow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  bottom: -12px;
}

.swap-modal-new .up-down-arrow {
  display: flex;
  border: 1px solid #EBEBEB;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* padding: 5px; */
  width: 3em;
  height: 3em;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%); */
  text-align: center;
  z-index: 9;
  background: #fff;
}

.swap-token-input .exchange-form {
  margin-top: 0em;
}

.up-down-arrow svg {
  transform: rotate(90deg);
}



/* Table  */
.table_badge {
  --_badge-background: var(--background, var(--primary-color));
  --_badge-color: var(--color, var(--tertiary-color));
  background: var(--_badge-background) !important;
  color: var(--_badge-color) !important;
  padding: 0.4em 2em !important;
  text-align: center !important;
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 25px !important;
  display: inline-block;
  font-size: 0.8em !important;
  font-weight: 600 !important;
}

.success {
  --background: #EBF6E0;
  --color: #5F843A;
}
.pending {
  --background: rgb(248, 213, 13, 15%);
  --color: rgb(248, 213, 13);
}
.fail {
  --background: #ff00000f;
  --color: red;
}

.amt_processing {
  color: red !important;
}

.amt_paid {
  color: #27C124 !important;
}

.table_code, .table_value {
  font-size: 0.85em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
  text-align: left;
  text-transform: capitalize;
}

.table_code span {
  /* display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all; */
}

.table_amount {
  font-size: 0.75em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
  text-align: left;
}

.wallet_table .table tr,.wallet_table .table thead tr {
  border-radius: 60px;
  box-shadow: rgb(228 228 228 / 0.1) 0px 1px 3px 0px, rgb(195 195 195 / 10%) 0px 0px 0px 1px;
}


.wallet_table thead tr th {
  padding: 1.2em;
  vertical-align: middle;
  font-size: 0.85em;
  font-weight: 500;
  text-align: left;
  border-radius: 60px;
  background: unset !important;
  box-shadow: unset !important;
  border: unset !important;
}

.wallet_table thead tr th:last-child{
  text-align: center;
}

.wallet_table .table tr td {
  background: unset !important;
  box-shadow: unset !important;
  border: unset !important;
  vertical-align: middle;
  padding: 1.2em;
}

.wallet_table .table tr td:first-child {
  text-align: start;
}

.wallet_table .table tr td:last-child {
  text-align: center;
}

.table_code {
  display: flex;
  /* grid-template-columns: 200px auto; */
  align-items: center;
  gap: 1em;
  margin-bottom: 0.4em;
  
}

.wallet_shuffle_icon {
  width: 3.5em;
  height: 3.5em;
  background: #F7F7F7;
  border-radius: 50%;
}

.wallet_table_head {}

.wallet_table .table {
  border-collapse: separate;
  border-spacing: 0 0.8em;
}

.wallet-tabs-content, .wallet-currency-frame {
  background: var(--overlay-bg);
  position: relative;
  z-index: 0;
}

.wallet-tabs-content::after {
  content: ' ';
  background: var(--secondary-color);
  height: 52px;
  top: 0;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.wallet-currency-card-frame {
  background: var(--secondary-color);
  padding: 2em;
  border-radius: 10px;
}

.wallet-currency-layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4em;
}

.wallet-card-icon h3 {
  font-size: 0.9em;
  font-weight: 400;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.wallet-icon {
  width: 2em;
  height: 2em;
  object-fit: contain;
}

.wallet-card-info h3 {
  font-size: 0.9em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0.5em;
}

.wallet-card-info p {
  font-size: 0.8em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
}

.wallet-currency-card {
  display: flex;
  flex-direction: column;
  gap: 2em;
  position: relative;
}

.wallet-currency-card::after {
  content: '';
  position: absolute;
  right: -35px;
  background: var(--border-color);
  width: 1px;
  height: 100%;
  transform: scale(0.6);
}

.wallet-currency-card:last-child::after {
  display: none;
}

.wallet-currency-action {
  gap: 1em;
}

.transaction_token {}

.table_token {
  border: 1px solid var(--border-color);
  border-radius: 25px;
  text-align: center;
  font-size: 0.7em;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--tertiary-color);
  padding: 0.4em 1em;
}

.crypto_amount_field .form-control,.deposit_amount_field .form-control {
  border: 1px solid var(--border-color)!important;
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
  box-shadow: unset !important;
  color: var(--tertiary-color);
  height: 50px;
  border-radius: 10px!important;
}
.crypto_amount_field .form-control::placeholder{
  color: var(--quaternary-color) !important;
  font-weight: 400;
}

.crypto_amount-bls {
  text-align: center;
}

.crypto_amount-bls h5 {
  font-size: 0.95em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
  text-align: center;
}

.crypto_amount-bls h5 span {
  font-weight: 700 !important;
  color: var(--tertiary-color) !important;
}

.crypto_address_card,.deposit_address_card {
  background: var(--overlay-bg);
  padding: 1.5em;
  border-radius: 10px;
}

.crypto_service_info {
  font-size: 0.8em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
  text-align: center;
}

.crypto_token_info {
  text-align: center;
  font-size: 0.8em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
}

.crypto_token_info span {
  font-weight: 600 !important;
  color: var(--tertiary-color) !important;
}


/* deposit details card style  */
.deposit_foreign_bank_details{
  border-radius: 10px;
  background: linear-gradient(45deg, #FFF5F5 100%, #FFFBEC 100%);
  overflow: hidden;
}
.deposit_bank_name{
  display: grid;
  grid-template-columns: 40px auto;
  align-items: center;
}
.deposit_bank_info h3{
  font-size: 0.9em;
  font-weight: 500;
  color: var(--tertiary-color);
  margin-bottom: 0.5em;
}
.deposit_bank_info h5,.deposit_bank_type{
  font-size: 0.85em;
  font-weight: 400;
  color:var(--quaternary-color);
  margin-bottom: 0;
}
.deposit_bank_other_details{
  background:   #FFFDF6;

}
.deposit_bank_accout_number  h3{
  font-size: 1.1em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
  letter-spacing: 6px;
  text-align: center;
}
.deposit_other_card{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
gap: 1.5em;
}
.deposit_other_titles h5{
font-size: 1em;
font-weight: 500;
margin-bottom: 0;
color: var(--tertiary-color);
}
.deposit_other_info_card h4{
  font-size: 0.8em;
  font-weight: 300;
  margin-bottom: 0.5em;
  color: var(--quaternary-color);
}
.deposit_other_info_card p{
  font-size: 0.85em;
  font-weight: 500;
  margin-bottom: 0em;
  color: var(--tertiary-color);
}
.deposit_space_bar{
  border-bottom: 1px solid var(--border-color);
  margin: 1.5em 0; 
}
.other_links{
  margin-bottom: 0;
  color: var(--tertiary-color);
  font-size: 0.9em;
  font-weight: 400;
  text-align: center;
}
/* deposit details card style  */


.commission-box{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
  margin-top: 1em;
  margin-bottom: 0.5em;
  gap: 0.8em;
}

.commission-based-card{
  background: var(--overlay-bg);
  padding: 1em;
  border-radius: 10px;
  margin-top: 1.5em;
}

.commission-based-landing-card{
  background: var(--secondary-color);
  padding: 1em;
  border-radius: 10px;
}

.commission-based-landing-card .commission-box{
  margin-top: 0;
  margin-bottom: 0;
}

.commission-based-card .commission-box{
  margin-top: 0;
  margin-bottom: 0;
}

.exchange-rate-card{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

.commission-card{
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:1em;
}

.commission-card h5{
  font-size: 0.8em;
  font-weight: 300;
  color: var(--quaternary-color);
  margin-bottom: 0;
}

.commission-card h5:nth-child(2){
  text-align: right;
  font-weight: 600;
  color: var(--tertiary-color);
}

.error-msg.text-danger.select-date.buy-form.error-text {
  margin-top: -12px !important;
}

.gender-check-box .pretty input:checked~.state.p-primary-o label:before, .gender-check-box .pretty.p-toggle .state.p-primary-o label:before{
  border-color: var(--primary-color);
}

.gender-check-box .pretty.p-default:not(.p-fill) input:checked~.state.p-primary-o label:after{
  background-color: var(--primary-color)!important;
}
.addres-card-bank{
  background-color: var(--light-color);
  padding: 1em;
  border-radius: 10px;
  text-align: center;
  margin-top: 1.5em;

}
.addres-card-bank p{
  font-size: 1em;
    font-weight: 400;
    color: var(--tertiary-color);
    margin-bottom: 0em;
    line-height: 1.4;
}
.bank-tab-address{
  font-size: 0.85em;
    font-weight: 400;
    margin-bottom: 0;
    color: var(--quaternary-color);
    margin-top: 1em;
    text-align: center;
    line-height: 1.5;
    word-break: break-word;
}