:root {
    --black-color: #222222;
    --white-color:#fff;
    --bg-grey:#F2F0EA;
}

.new-main-wrapper{
    display: flex;
    align-items: flex-start;
    /* min-height: 90vh; */
    height: 93vh;
}
.sidebar-wrapped{
    width:  20%;
    height: 100%;
    background-color: var(--white-color);
    border-right: 1px solid var(--bg-grey);
}
.nav-item{
 
}
.nav-items{
    font-size: 0.95em;
    font-weight: 500;
    color: var(--black-color);
    line-height: 1.4;
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    gap: 1em;
    padding: 1.2em 2em;
}
.nav-item.active{
position: relative;
background-color: var(--bg-grey);
}
.nav-item.active::after{
    content: ' ';
    position: absolute;
    background-color: var(--primary-color);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    top: 50%;
}
.sidebar-wrapped .nav-item:hover{
    background-color: var(--bg-grey);
}
.nav-break-wrapped{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--bg-grey);
    /* height: 50px; */
    z-index: 999;
}
.nav-break-wrapped ul{
    display: flex;
    align-items: center;
    justify-content:space-around;
    margin-bottom: 0;
    padding: 0.5em 0;
}
.nav-break-wrapped .nav-item.active::after {
 display: none;
}
.nav-break-wrapped .nav-item.active {
 background-color: var(--white-color);
 border-radius: 50%;
}
.nav-break-wrapped .nav-item.active svg{
fill: var(--black-color)!important;
}
.nav-break-wrapped .nav-items{
padding: 1em;
display: inline-block!important;
}