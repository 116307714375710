
.exchange-widget-modal.modal {
    --bs-modal-bg: #f3e2c3!important;
    --bs-modal-border-radius: 0!important;
    --bs-modal-inner-border-radius: 0!important;
    font-size: 14px!important;

}
.exchange-widget-modal.modal .efi-swap-icons{
   display: none!important;

}
.exchange-widget-modal .modal-content {
    padding: 2em;
    border-radius: 0!important;
}
.exchange-widget-modal .modal-header {
    padding: 0 !important;
    border-bottom: 1px solid transparent !important;
    position: absolute;
    right: 1.5em;
    z-index: 99;
}
.exchange-widget-modal .modal-body {
    padding: 0 !important;
}
.widget-header-sec .nav-pills {
    width: 100%;
    margin-bottom: 1.5em;
}
.widget-header-sec .nav-item:first-child {
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
}
.widget-header-sec .nav-item {
    background: #ffffff47;
    padding: .7em;
}
.widget-header-sec .nav-item:first-child .nav-link.active {
    color: #008826 !important;
}
.widget-header-sec .nav-item:last-child {
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.widget-header-sec .nav-item:last-child .nav-link.active {
    color: red !important;
}
.widget-header-sec .nav-link.active {
    background: var(--secondary-color) !important;
    border-radius: 10px !important;
}

.widget-header-sec .nav-link {
    background-color: initial !important;
    border-radius: 0 !important;
    color: #666 !important;
    font-size: .85em;
    font-weight: 600;
}