/* .static-pag-sec{
    margin-top: 4em;
    margin-bottom: 4em;
}

.section-title{
    text-align: center;
}

.section-title h1{
    color: var(--tertiary-color);
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 0.5em;
}

.section-title p{
    color: rgba(87, 87, 87, 1);
    font-size: 1em;
    max-width: 600px;
    line-height:1.8;
    margin: auto;
}

.static-box{
    background:var(--secondary-color);
    box-shadow: 0px 0px 250px 0px rgba(6, 31, 17, 0.07);
    padding: 3em;
    margin-top: 3em;  
    border-radius: 10px;    
}

.static-box{
    background:var(--secondary-color);
    box-shadow: 0px 0px 250px 0px rgba(6, 31, 17, 0.07);
    padding: 2em;
    margin-top: 2em;      
}

.static-card h3{
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 1em;
}

.static-card p{
    color: rgba(87, 87, 87, 1);
    font-size: 0.9em;
}

.privacy-policy-details{
    margin-bottom: 2em;
}

.privacy-policy-details h4{
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 600;
} */

.static-pag-sec {
    /* margin-top: 4em;
    margin-bottom: 4em; */
}

.static-page-header {
    background: linear-gradient(90deg, #161234 0%, #030613 102.51%);
    min-height: 15vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.static-page-header .static-section-title h1 {
    font-size: 2.5em;
    font-weight: 800;
    color: var(--secondary-color);
    margin-bottom: .2em;
    line-height: 1.4;
    text-align: center;
}

.static-page-header h4 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1.4;
    color: var(--secondary-color);
    opacity: .7;
    text-align: center;
}


.section-title {
    text-align: center;
    width: 100%;
}

.section-title p {
    line-height: 1.8;
    color: var(--quaternary-color);
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
    max-width: 600px;
    margin: 0 auto;
}

.static-card {
    margin: 3em 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 2em;
    border-radius: 12px;
}

.static-card p {
    line-height: 1.8;
    color: var(--quaternary-color);
    margin-bottom: .8em;
    font-size: 0.9em;
    font-weight: 400;
}

.static-card h4 {
    font-size: 1em;
    font-weight: 600;
    color: var(--tertiary-color);
    line-height: 1.5;
    margin-bottom: .5em;
}

.static-card ul li {
    line-height: 1.8;
    color: var(--quaternary-color);
    margin-bottom: 0.5em;
    font-size: 0.9em;
    font-weight: 400;
}

.static-card ul {
    margin-left: .5em;
}

.static-card strong{
    color: var(--tertiary-color);
    font-weight: 500 !important;
}