:root {
    --black-color: #222222;
    --black-text: #0C0E10;
    --thin-black: #202020;
    --trade-buy-color: #52E3A6;
    --trade-sell-color: #FF3B3E;
    --white-color: #fff;
    --input-bg: #FFFFFF17;
    --grey-color: #8F9899;
    --text-grey: #D2D2D2;
    --trade-border-color: #4F5459;
}

.trade-body {
    background-color: var(--black-color);
    min-height: 100vh;
    overflow: hidden;
}
.trade-body .trade-pair-wrapped .react-loading-skeleton{
    opacity: 0.2;
}
.modal-drag-wrapped .react-loading-skeleton{
    opacity: 0.2;
}
.system-pair-modal .offcanvas-body .trade-pair-wrapped .trade-pair-list-frame .react-loading-skeleton{
    opacity: 0.2;
}
.trade-body .header-nav-center .navbar-nav .nav-link.active::after{
    bottom: -20px;
}
.trade-body .logo{
    max-width: 8em!important;
}
.trade-body .header-nav-center .navbar-expand-lg .navbar-nav .nav-link,
.trade-body .user-dropdown-sec .dropdown-toggle span{
color: var(--white-color);
}
.trade-body .user-dropdown-sec .dropdown-toggle svg path{
    fill: var(--white-color);
}
.trade-nav-system{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: 0.5em 1em;
}
.trade-nav-btn-stock{
    background-color: transparent!important;
    border: 0!important;
    padding: 0!important;
}
.trade-nav-wrapped {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: 0 0.5em;
    padding-left:0;
    background-color: #131722;
}

.trade-nav-list {
    display: flex;
    align-items: center;
    gap: 3em;
    flex: 1 auto;
    overflow: auto;
    height: 100%;
}

.trade-nav-list:hover:-webkit-scrollbar {
    height: 3px;
}

.trade-nav-list::-webkit-scrollbar {
    height: 0px;
    scrollbar-width: thin !important;
}

.trade-nav-list::-webkit-scrollbar-thumb {
    background: #414141;
}

.trade-nav-list::-webkit-scrollbar-track {
    background-color: transparent;
}

.trade-nav-list p {
    font-size: 0.85em;
    font-weight: 400;
    color: var(--white-color);
    margin-bottom: 0;
    white-space: nowrap;
}

.trade-nav-buy-sell-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    width: 13%;
}

.trade-btn {
    --_background: var(--background, var(--primary-color));
    --_color: var(--color, var(--tertiary-color));
    --_border: var(--border, transparent);
    --_btn-width: var(--btn-width, auto);
    background: var(--_background) !important;
    color: var(--_color) !important;
    border: 1px solid var(--_border) !important;
    padding: var(--padding, 0.4em 2em) !important;
    width: var(--_btn-width);
    text-align: center !important;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out !important;
    position: relative;
    overflow: hidden;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -o-border-radius: 8px;
    -ms-border-radius: 8px;
    border-radius: 8px !important;
    display: inline-block;
    font-size: 0.7em !important;
    font-weight: 600 !important;
}

.buy {
    --background: var(--trade-buy-color);
    --color: var(--black-text);
    --btn-width: 100%;
    --border: transparent;
    --padding: 0.8em 2em;
    text-transform: uppercase;
}

.sell {
    --background: var(--trade-sell-color);
    --color: var(--black-text);
    --btn-width: 100%;
    --border: transparent;
    --padding: 0.8em 2em;
    text-transform: uppercase;
}

.trade-pair-head {
    padding: 1em;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99;
    background-color: var(--black-color);
}

.trade-pair-head .input-group {
    background-color: var(--input-bg) !important;
    border-radius: 8px;
}

.trade-pair-head .form-control {
    background-color: transparent !important;
    border: 0;
    color: var(--white-color) !important;
    height: 40px;
}

.trade-pair-head .form-control::placeholder {
    font-size: 0.8em;
    font-weight: 500;
    color: var(--grey-color) !important;
}

.trade-pair-head .form-control:focus,
.trade-pair-head .form-control:disabled,
.trade-pair-head .form-control:active {
    background-color: transparent !important;
    border: 0;
    box-shadow: unset !important;
}

.input-group-text {
    border: 0 !important;
    background-color: transparent !important;
}

.btn-action-input {
    border: 0 !important;
    background-color: transparent !important;
    padding: 0 !important;
}

.trade-pair-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    padding: 0.15em 1em;
    transition: 0.5s all ease;
}
.trade-system-pair-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    transition: 0.5s all ease;
}

.trade-pair-card.active {
    background-color: var(--input-bg);
}

.trade-pair-card:hover {
    cursor: pointer;
    background-color: var(--input-bg);
}

.trade-pair-one {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    gap: 0.5em;
    flex: 1 auto;
}
.trade-system-pair-one {
    display: grid;
    grid-template-columns: 43px auto;
    align-items: center;
    /* gap: 1em; */
    flex: 1 auto;
}

.trade-pair-label {
    font-size: 0.75em;
    font-weight: 600;
    color: var(--white-color);
    line-height: 2;
}
.trade-nav-system{
    background-color: var(--input-bg);
}

.trade-system-pair-label {
    font-size: 0.75em;
    font-weight: 600;
    color: var(--white-color);
    line-height: 2;
}

.trade-system-pair-label span{
   margin-left: 1em;
}

.trade-pair-img {
    width: 2.5em;
    height: 2.5em;
    object-fit: contain;
}

.trade-system-pair-img {
    width: 2.5em;
    height: 2.5em;
    object-fit: contain;
}

.trade-pair-rate {
    --_color: var(--color, var(--primary-color));
    font-size: 0.7em;
    font-weight: 500;
    line-height: 1.6;
    color: var(--_color);
}

.high {
    --color: var(--trade-buy-color);
}

.low {
    --color: var(--trade-sell-color);
}

.trade-pair-wrapped {
    /* padding: 1.5em; */
    position: relative;
}

.trade-pair-wrapped {
    max-height: calc(100vh - 100px);
    overflow: auto;
    width: 15%;
}
.trade-socket-wrapped{
    max-height: calc(100vh - 100px);
    overflow: hidden;
    width: 20%;
}

.trade-pair-wrapped::-webkit-scrollbar {
    width: 3px;
    scrollbar-width: thin !important;
}

.trade-pair-wrapped::-webkit-scrollbar-thumb {
    background: #414141;
}

.trade-pair-wrapped::-webkit-scrollbar-track {
    background-color: transparent;
}

.trade-socket-tabs .nav-link {
    padding: 0 !important;
    border: 0 !important;
    opacity: 0.5;
    cursor: pointer;
}

.trade-socket-tabs .nav {
    gap: 1em;
    background-color: var(--input-bg);
    padding: 0.3em 1.5em 0.4em 1.5em;
}

.trade-socket-tabs .nav-link.active {
    padding: 0 !important;
    border: 0 !important;
    opacity: 1;
    background-color: transparent !important;
}

.trade-stack-body {
    position: relative;
    padding: 1em 1.5em;
    padding-bottom: 0;
}

.trade-stack-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    width: 100%;
    /* z-index: 99; */
    background-color: var(--black-color);
    margin-bottom: 1em;
}

.stack-price-label {
    font-size: 0.85em;
    font-weight: 500;
    color: var(--grey-color);
    text-align: left;
}

.stack-amt-label {
    font-size: 0.85em;
    font-weight: 500;
    color: var(--grey-color);
    text-align: right;
}

.trade-socket-stack {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2em 0;
}

.stack-price {
    --_color: var(--color, var(--primary-color));
    font-size: 0.85em;
    font-weight: 500;
    /* line-height: 1.6; */
    color: var(--_color);
}

.high {
    --color: var(--trade-buy-color);
}

.low {
    --color: var(--trade-sell-color);
}

.stack-amt {
    font-size: 0.85em;
    font-weight: 500;
    /* line-height: 1.6; */
    color: var(--text-grey);
}
.trade-stack-frame{
    height:calc(100vh - 185px);
    overflow: hidden;
}

.trade-stack-hight-frame {
    height: 49%;
    overflow: auto;
}

.trade-stack-hight-frame::-webkit-scrollbar {
    width: 0px;
    scrollbar-width: thin !important;
}

.trade-stack-frame::-webkit-scrollbar {
    width: 3px;
    scrollbar-width: thin !important;
}

.trade-stack-frame::-webkit-scrollbar-thumb {
    background: #414141;
}

.trade-stack-frame::-webkit-scrollbar-track {
    background-color: transparent;
}

.trade-stack-low-frame {
    height: 49%;
    overflow: auto;
}
.trade-empty-avater{
    width: 5em;
    height: auto;
    object-fit: contain;
    opacity: 0.5;
}
.trade-empty-frame h4{
    font-size: 1em;
    font-weight: 500;
    color: var(--grey-color);
    margin-bottom: 0;
    line-height: 1.4;
    text-align: center;
}
.trade-empty-frame{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5em;
}
.trade-stack-low-frame::-webkit-scrollbar {
    width: 0px;
    scrollbar-width: thin !important;
}

.trade-batch {
    /* text-align: center;
    padding: 0.3em 0;
    color: var(--white-color);
    font-size: 0.85em;
    font-weight: 500;
    line-height: 1.8; */
    margin: 0.5em 0;
    border: 0.6px dashed var(--input-bg);

}

.trade-layout-frame {
    display: flex;
    min-height: calc(100vh - 100px);
}

.tradingview-widget-container {
    flex: 1 auto;
    width: auto !important;
    /* height: 100%; */
    height: calc(100vh - 100px) !important;

}

.modal-drag-wrapped {
    background-color: var(--thin-black);
    position: absolute;
    z-index: 1050;
    cursor: move;
    padding: 1.5em;
    padding-top: 0.5em;
    width: calc(100% - 72%);
    bottom: 0;
    right: 0;
}

.trade-modal-header .cancel-btn {
    background-color: transparent !important;
    border: 0 !important;
    width: 2em !important;
    height: 2em !important;
    padding: 0 !important;

}

.trade-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
}

.trade-modal-header .nav-pills {
    border-bottom: 1px solid var(--trade-border-color);
}

.trade-modal-header .nav-pills .nav-link {
    background-color: transparent !important;
    border: 0 !important;
    padding: 0.6em 2em !important;
    color: var(--white-color) !important;
}

.trade-modal-header .nav-pills .nav-item:first-child .nav-link.active {
    color: var(--trade-buy-color) !important;
    position: relative;
}

.trade-modal-header .nav-pills .nav-item:first-child .nav-link.active::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 8px;
    background-color: var(--trade-buy-color);
    bottom: 0;
    left: 0;
}

.trade-modal-header .nav-pills .nav-item:last-child .nav-link.active {
    color: var(--trade-sell-color) !important;
    position: relative;
}

.trade-modal-header .nav-pills .nav-item:last-child .nav-link.active::after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 8px;
    background-color: var(--trade-sell-color);
    bottom: 0;
    left: 0;
}

.trade-modal-type {
    display: grid;
    grid-template-columns: 50px auto;
    align-items: center;
    /* gap: 0.5em; */
    padding: 1.5em 0;
    padding-bottom: 2em;
    /* p */
}

.trade-modal-type-img {
    width: 2.5em;
    height: 2.5em;
    object-fit: contain;
}

.trade-modal-type-name {
    font-size: 0.9em;
    font-weight: 500;
    color: var(--white-color);
    text-transform: uppercase;
}

.trade-modal-fields .form-control {
    height: 60px;
    border: 1px solid var(--trade-border-color);
    border-radius: 8px;
    color: var(--white-color) !important;
    background-color: transparent !important;
}

.trade-modal-fields .form-control::placeholder {
    font-size: 0.85em;
    font-weight: 500;
    color: var(--grey-color) !important;
}

.trade-modal-fields .form-label {
    position: absolute;
    background: var(--thin-black);
    padding: 0 5px;
    color: var(--trade-border-color);
    top: -15px;
    left: 15px;
    margin: 0;
    font-size: 0.9em;
    font-weight: 500;
}

.trade-modal-fields .form-label span {
    color: #FF3B3E;
}

.trade-modal-fields .form-control:focus,
.trade-modal-fields .form-control:disabled,
.trade-modal-fields .form-control:active {
    background-color: transparent !important;
    border: 1px solid var(--trade-border-color);
    box-shadow: unset !important;
}

.range-label span {
    color: var(--trade-border-color);
    font-size: 0.9em;
    font-weight: 500;
}

.trade-modal-action {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
    padding-top: 1em;
}

.trade-bls-card span {
    color: var(--white-color);
    font-weight: 600;
    margin-left: 1em;
}

.bls-trade {
    --background: var(--input-bg);
    --color: var(--grey-color);
    --btn-width: auto;
    --border: transparent;
    --padding: 1em 2em;
    text-transform: uppercase;
}

.buy-trade {
    --background: var(--trade-buy-color);
    --color: var(--black-text);
    --btn-width: auto;
    --border: transparent;
    --padding: 1em 2em;
    text-transform: uppercase;
}

.sell-trade {
    --background: var(--trade-sell-color);
    --color: var(--black-text);
    --btn-width: auto;
    --border: transparent;
    --padding: 1em 2em;
    text-transform: uppercase;
}
.system-pair-modal{
    background-color: var(--black-text)!important;
}
.system-pair-modal .offcanvas-title{
   color: var(--white-color)!important;
   font-size: 1.2em;
   font-weight: 500;
}
.system-pair-modal .offcanvas-body{
    padding:0!important;
}
.system-pair-modal .trade-socket-wrapped{
    width:100%!important;
}
.system-pair-modal .trade-stack-head{
    padding: 0.5em;
}
.system-pair-modal .offcanvas-body .trade-pair-wrapped{
    width:100%!important;
    max-height: calc(100vh - 66px)!important;
}
.system-pair-modal .offcanvas-body .trade-socket-wrapped{
    width:100%!important;
    max-height: calc(100vh - 66px)!important;
}
.system-pair-modal .offcanvas-body .trade-stack-frame {
    height: calc(100vh - 158px)!important;
    overflow: hidden;
}
.system-pair-modal .btn-close{
    --bs-btn-close-bg:url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="512" height="512" viewBox="0 0 320.591 320.591" ><g fill="%23fff"><path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.37 30.37 0 0 1-21.256 7.288" data-original="%23000000" ></path><path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922q-.684.73-1.414 1.414a30.37 30.37 0 0 1-23.078 7.288" data-original="%23000000" ></path></g></svg>');
}
.js-copyright-label{
    display: none!important;
}