:root {
    --black-color: #222222;
    --black-text: #0C0E10;
    --thin-black: #202020;
    --white-color: #fff;
    --white-grey-color: #FCFCFA;
    --grey-color: #8F9899;
    --try-grey: #F4F5F5;
    --trade-border-color: #4F5459;
    --copy-color:#EAF3FF;
    --link-color:#298BFF;
}
.wallet-wrapped,
.profile-full-wrapped {
  background: var(--secondary-color);
  /* min-height: 90vh; */
  width: 100%;
  padding: 2em;
  height: 100%;
  overflow: auto;
}
.wallet-details-wrapped {
  background: var(--secondary-color);
  /* min-height: 90vh; */
  width: 100%;
  height: 100%;
  overflow:auto;
}
.wallet-details-wrapped::-webkit-scrollbar{
    width: 0;
}

.wallet-content-body table{
    border-collapse: initial;
    border-spacing: 0 .8em;
}
.wallet-content-body tr th{
    color: var(--grey-color);
    font-weight: 500;
    font-size: 0.9em;
}
.wallet-content-body tr td{
   background-color: var(--white-grey-color); 
}
.wallet-content-body tr th,.wallet-content-body tr td{
    vertical-align: middle;
    padding: 12px;
    border: 0;
}
.wallet-content-body tr td:nth-child(n+3):nth-child(-n+6) {
    text-align: center;
}
.wallet-content-body tr th:nth-child(n+3):nth-child(-n+6) {
    text-align: center;
}

.wallet-token-table table{
    border-collapse: initial;
    border-spacing: 0 .8em;
}
.wallet-token-table tr th{
    color: var(--grey-color);
    font-weight: 500;
    font-size: 0.9em;
}
.wallet-token-table tr td{
   background-color: var(--white-grey-color); 
   font-weight: 600;
   font-size: 0.85em;
   color: var(--black-text);
}
.wallet-token-table tr th,.wallet-token-table tr td{
    vertical-align: middle;
    padding: 12px;
    border: 0;
    text-transform:capitalize;
}
.wallet-info-td-card{
    display: grid;
    grid-template-columns: 35px auto;
    align-items: center;
    gap: 1em;
}
/* .td-card-info{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;
}
.td-value-card{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4;   
} */
.wallet-content-body tr td{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.4; 
}
.td-value-card span{
    color: var(--grey-color);
    background-color: var(--try-grey);
    padding: 0.5em 1em;
    border-radius: 8px;   
    margin-left: 1em;
    font-size: 0.75em;
}
.wallet-content-body button{
    background-color: transparent;
    border: 0;
    padding: 0;
}
.wallet-content-body button:focus,
.wallet-content-body button:active,
.wallet-content-body button:hover,
.wallet-content-body button:disabled
{
    background-color: transparent!important;
    border: 0;
    padding: 0;
}
.wallet-titles-label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}
.wallet-head-action {
    display: flex;
    gap: 1em;
}

.wallet-frame{
    gap: 1em;
    border-bottom: 1px solid var(--try-grey);
}
.wallet-frame .nav-link.active{
    border-bottom: 2px solid var(--primary-color);
}
.wallet-frame .nav-link{
    background-color: unset!important;
    font-weight: 500;
    color: var(--black-text);
    font-size: 0.9em;
    border-radius: 0!important;
    border-bottom: 2px solid transparent;
    padding: 1em 0!important;
}
.wallet-section-titles{
    margin-bottom: 1em;
}
.wallet-section-titles h2{
    font-size: 1.4em;
    font-weight: 600;
    line-height: 1.4;
    color: var(--black-text);
    margin-bottom: 0;
}

.wallet-titles-label{
    font-size: 1em;
    font-weight: 600;
    color: var(--black-text);
    /* line-height: 2.6; */
}
.wallet-details-head{
    display: grid;
    grid-template-columns: 24px auto;
    align-items: center;
    gap: 0.5em;
    padding: 2em;
    border-bottom: 1px solid var(--bg-grey);
}
.wallet-token-frame{
    padding: 2em;
}
.token-card{
    background-color: var(--try-grey);
    padding: 1.5em;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.token-card-avater{
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    gap: 1em;
}
.token-card-img{
    width:2.5em;
    height: 2.5em;
    object-fit: contain;
}
.token-card-avater-label{
    font-size: 0.85em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.6;
}
.token-card-info{
    text-align: right;
}
.token-card-info-value{
    font-size: 1em;
    font-weight: 600;
    color: var(--black-text);
    line-height: 1.6;
    margin-bottom: 0.5em;
}
.token-card-info-status{
    font-size: 0.85em;
    font-weight: 400;
    color: var(--grey-color);
}
.wallet-token-wrapped{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5em;
    align-items: center;
}
.wallet-token-titles{
    padding-top: 2em;
    padding-bottom: 1em;
}
.wallet-token-titles h2 {
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.4;
    color: var(--black-text);
    margin-bottom: 0;
}

.wallet-token-nav{
    gap: 2em;
    border-bottom: 1px solid var(--try-grey);
}
.wallet-token-nav .nav-link.active{
    border-bottom: 2px solid var(--primary-color);
}
.wallet-token-nav .nav-link{
    background-color: unset!important;
    font-weight: 500;
    color: var(--black-text);
    font-size: 0.9em;
    border-radius: 0!important;
    border-bottom: 2px solid transparent;
}
.copy-token-btn{
    background-color:var(--copy-color)!important ;
    width: 2.5em;
    height: 2.5em;
    display: flex!important;
    align-items: center;
    justify-content: center;
    border-radius: 50%!important;
    border: 0!important;
    padding: 10px!important;
}
.td-copy-token{
    display: grid;
grid-template-columns: 110px auto;
align-items: center;
gap: 1em;
}
.token-date{
    font-weight: 500;
}
.token-status-badges{
    --_background:var(--background,var(--primary-color));
    --_color:var(--color,var(--primary-color));
    padding: 0.5em 1.8em;
    text-align: center;
    background-color: var(--_background);
    color: var(--_color);
    width: fit-content;
    border-radius: 0.375rem;
    font-weight: 600;
    font-size: 0.85em;
}
.success{
    --background:#EBF6E0;
    --color:#5F843A;
}
.fail{
    --background:#f8000057;
    --color:#ec0606;
}
.pending{
    --background:#e7ac0954;
    --color:#f86b06;
}
.view-btn{
    background-color: var(--copy-color)!important;
    color: var(--link-color)!important;
    padding: 0.5em 2em!important;
    border: 0!important;
    font-weight: 600!important;
    font-size: 0.85em!important;
    transition: all ease 0.5s;
}
.view-btn:hover{
   color: var(--copy-color)!important;
   background-color: var(--link-color)!important; 
}
.tab-nav-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add-token{
    color: var(--trade-buy-color)!important;
  }

.minus-token{
    color:var(--trade-sell-color)!important;
  }