.sdk-widget-main{
    height: 100vh;
    display: grid;
    place-items: center;
    background-color: rgb(243 226 195);
}
.buy-widget-caution-part {
    border-radius: 10px;
    padding: 0.5em;
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    background: var(--secondary-color);
}

.caution-info {
    font-size: 0.8em;
    font-weight: 400;
    color: var(--tertiary-color);
    line-height: 1.8;
}
@media (max-width: 767.98px) {
    .sdk-widget-main{
        height: 100%!important;
        padding: 1em;
    }
}
.feature-sell-box{
    background: #FFFFFF82;
    padding: 2em;
    border-radius: 10px;
    width: 100%;
}
@media (max-width: 767.98px) {
    .feature-sell-box{
        padding: 1em;
    }
}
.feature-sdk-box{
    width: calc(100% - 50%);
}
@media (max-width: 767.98px) {
    .feature-sdk-box{
        width: 100%!important;
    }
}
.feature-sdk-sell-full-frame {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}
.feature-sdk-sell-input-wrapped {
    display: flex;
    gap: 1em;
    flex-direction: column;
    align-items: center;
    position: relative;
}


.feature-sdk-sell-input-wrapped .feature-sdk-sell-fields-card:nth-child(1) {
    z-index: 99999;
}
.efi-swap-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    z-index: 999999;
}
.feature-sdk-sell-fields-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: #FFFFFF99;
    backdrop-filter: blur(62px);
}
@media (max-width: 767.98px) {
    .feature-sdk-sell-fields-card {
        grid-template-columns: repeat(1, 1fr)!important;
    }
}
.feature-sdk-sell-fields-type, .feature-sdk-sell-fields-enter {
    padding: 1.5em;
}
.feature-sdk-sell-label-enter {
    font-size: 0.9em;
    font-weight: 600;
    color: #010101;
    margin-bottom: 1.5em;
}
.feature-sdk-sell-fields-card .form-control {
    background-color: transparent !important;
    color: #7D7D7D !important;
    font-size: 1.5em;
    font-weight: 700;
    border: 1px solid #00000026 !important;
    padding-left: 16px !important;
    height: 45px;
}
.feature-sdk-sell-fields-card .form-control:focus,
.feature-sdk-sell-fields-card .form-control:active {
    background-color: transparent !important;
    border: 1px solid #00000026 !important;
    box-shadow: unset!important;
}
.feature-sdk-sell-fields-type {
    /* border-bottom: 1px solid var(--secondary-color); */
    background: #f8f9fa;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.feature-sdk-sell-fields-label{
    font-size: 1.1em;
    font-weight: 600;
    text-transform: capitalize;
    color: #010101;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
    margin-bottom: 1.5em;
}
.feature-sdk-sell-grid {
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: flex-end;
}
@media (max-width: 767.98px) {
    .feature-sdk-sell-grid {
        justify-content: center!important;
    } 
    .feature-sdk-sell-fields-label{
        justify-content: center!important;
    } 
}
.feature-sdk-select .fillert-drop {
    width: auto !important;
}
.feature-sdk-sell-fields-card .feature-sdk-sell-receive-text {
    color: #7D7D7D !important;
    font-size: 1.5em;
    font-weight: 700;
    border: 0 !important;
}
.feature-sdk-sell-info{
    border: 1px dashed #0000003B;
    padding: 0.7em;
    border-radius: 10px;
    background: #0000000A;   
}
.feature-sdk-sell-info p {
    color: #010101;
    font-size: 0.9em;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
    line-height: 1.8;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.feature-sdk-sell-com-card {
    background: var(--secondary-color);
    padding: 1em;
    border-radius: 10px;
}
.feature-sdk-sell-com-card h5 {
    font-size: 0.8em;
    font-weight: 300;
    color: var(--quaternary-color);
    margin-bottom: 0;
}
.feature-sdk-sell-com-card h5:nth-child(2) {
    text-align: right;
    font-weight: 600;
    color: var(--tertiary-color);
}
.feature-sdk-sell-check-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF99;
    border-radius: 10px;
    padding: 1em;
}
.feature-sdk-sell-check-frame .pretty.p-default input:checked~.state label:before {
    border-color: var(--primary-color) !important;
}
.feature-sdk-sell-check-frame .pretty.p-default input:checked~.state label:after {
    background-color: var(--primary-color) !important;
}
.feature-sdk-sell-check-frame .pretty .state label {
    font-size: 0.85em;
    font-weight: 600;
    color: #000;
}
.feature-sdk-sell-check-frame .pretty .state {
    margin-top: -2.5px;
}
.feature-sdk-head{
    display: flex;
        gap: 1em;
        align-items: center;
        margin-bottom: 1.5em;
}

.feature-sdk-head .back-arrow-btn {
    width: 2em;
    cursor: pointer;
}
.feature-sdk-head h4 {
    font-size: 1.1em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 0;
}
.feature-sdk-sell-details{
    display: grid;
        grid-template-columns: repeat(2, 1fr);
        background-color: var(--light-color);
        border-radius: 10px;
        padding: 1.5em;
        margin-bottom: 1em !important;
        position: relative;
        gap: 2em;
}
@media (max-width: 767.98px) {
    .feature-sdk-sell-details{
        grid-template-columns: repeat(1, 1fr)!important;
        padding: 1em!important;
        gap: 0!important;
    }
    .feature-sdk-sell-details .feature-sdk-sell-amt-details ,.feature-sdk-sell-details .feature-sdk-sell-qr-scan{
        grid-area: unset!important; 
    }
    .feature-sell-box .auth_buy_code {
        grid-template-columns: auto 50px !important;
    }
}
.feature-sdk-sell-details .feature-sdk-sell-amt-details {
    grid-area: 1 / 1 / 2 / 3;
    background-color: var(--light-color);
    border-radius: 10px;
    margin-bottom: 1em !important;
    display: flex
;
    flex-direction: column;
    justify-content: space-between;
}
.feature-sdk-sell-details .feature-sdk-sell-qr-scan {
    grid-area: 1 / 3 / 2 / 4;
    display: flex
;
    justify-content: center;
}
.feature-sdk-sell-amt-details-card {
    display: flex
;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
}



/* Buy section Style  */
.feature-sdk-buy-full-frame {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}
.feature-sdk-buy-input-wrapped {
    display: flex;
    gap: 1em;
    flex-direction: column;
    align-items: center;
    position: relative;
}


.feature-sdk-buy-input-wrapped .feature-sdk-buy-fields-card:nth-child(1) {
    z-index: 1050;
}

.feature-sdk-buy-input-wrapped .feature-sdk-buy-fields-card:nth-child(2) {
    z-index: 99999;
}


.feature-sdk-buy-fields-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: #FFFFFF99;
    backdrop-filter: blur(62px);
}
@media (max-width: 767.98px) {
    .feature-sdk-buy-fields-card {
        grid-template-columns: repeat(1, 1fr)!important;
    }
}
.feature-sdk-buy-fields-type, .feature-sdk-buy-fields-enter {
    padding: 1.5em;
}
.feature-sdk-buy-label-enter {
    font-size: 0.9em;
    font-weight: 600;
    color: #010101;
    margin-bottom: 1.5em;
}
.feature-sdk-buy-fields-card .form-control {
    background-color: transparent !important;
    color: #7D7D7D !important;
    font-size: 1.5em;
    font-weight: 700;
    border: 1px solid #00000026 !important;
    padding-left: 16px !important;
    height: 45px;
}
.feature-sdk-buy-fields-card .form-control:focus,
.feature-sdk-buy-fields-card .form-control:active {
    background-color: transparent !important;
    border: 1px solid #00000026 !important;
    box-shadow: unset!important;
}
.feature-sdk-buy-fields-type {
    /* border-bottom: 1px solid var(--secondary-color); */
    background: #f8f9fa;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.feature-sdk-buy-fields-label{
    font-size: 1.1em;
    font-weight: 600;
    text-transform: capitalize;
    color: #010101;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
    margin-bottom: 1.5em;
}
@media (max-width: 767.98px) {
    .feature-sdk-buy-fields-label{
        justify-content: center!important;
    }
    .feature-sdk-buy-grid{
        justify-content: center!important; 
    }
    .feature-sdk-buy-type .half-sized ,.feature-sdk-sell-type .half-sized{
        width: 100% !important;
        margin-left: auto;
    }
}
.feature-sdk-buy-grid {
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: flex-end;
}
.feature-sdk-select .fillert-drop {
    width: auto !important;
}
.feature-sdk-buy-fields-card .feature-sdk-buy-receive-text {
    color: #7D7D7D !important;
    font-size: 1.5em;
    font-weight: 700;
    border: 0 !important;
}
.feature-sdk-buy-info{
    border: 1px dashed #0000003B;
    padding: 0.7em;
    border-radius: 10px;
    background: #0000000A;   
}
.feature-sdk-buy-info p {
    color: #010101;
    font-size: 0.9em;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
    line-height: 1.8;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.feature-sdk-buy-com-card {
    background: var(--secondary-color);
    padding: 1em;
    border-radius: 10px;
}
.feature-sdk-buy-com-card h5 {
    font-size: 0.8em;
    font-weight: 300;
    color: var(--quaternary-color);
    margin-bottom: 0;
}
.feature-sdk-buy-com-card h5:nth-child(2) {
    text-align: right;
    font-weight: 600;
    color: var(--tertiary-color);
}
.feature-sdk-head{
    display: flex;
        gap: 1em;
        align-items: center;
        margin-bottom: 1.5em;
}
.sdk-brand-card{
    position: absolute;
    top: 2%;
    left: 2%;
}
@media (max-width: 767.98px) {
    .sdk-brand-card{
        position: relative;
        padding: 1em 0;
    }
}

.widegt-tabs-link {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.widegt-tabs-box {
    background: #ffffff47;
    padding: .7em;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 1em;
    margin: auto;
    margin-bottom: 1.5em;
}

.widegt-tabs-box button {
    background-color: initial;
    border-radius: 0;
    color: #666;
    font-size: .85em;
    font-weight: 600;
    border: 0;
    padding: 0.8em 1em;
    font-family: "Red Hat Text", sans-serif;
}

.widegt-tabs-box button.active {
    background: var(--secondary-color);
    border-radius: 10px;
}

.widegt-tabs-box button:first-child.active {
    color: #008826 !important;
}

.widegt-tabs-box button:last-child.active {
    color: #ff0000 !important;
}

.widegt-tabs-close button {
    background-color: transparent;
    border: 0;
}
.feature-sdk-sell-input-wrapped .feature-sdk-sell-fields-card:nth-child(2){
    z-index: 9999;
}