.flow-stepper-frame ul {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  position: relative;
  overflow: hidden;
  min-height: 70px;
}

.withdraw-stepper .flow-stepper-frame ul {
  width: 100%;

}

.withdraw-stepper .flow-stepper-frame ul::after {

  transform: scale(0.68);

}

.flow-stepper-card span {
  text-align: center;
  position: absolute;
  top: 40px;
  white-space: nowrap;
}

.flow-stepper-frame ul li {
  z-index: 1050;
  /* flex: 0 1; */
  position: relative;
}

.flow-stepper-frame ul li::after {
  content: ' ';
  position: absolute;
  width: 90vw;
  height: 2px;
  background-color: #fff;
  top: 10px;
  left: 18px;
  transform-origin: center;
  z-index: -1;
  /* transform: scale(0.95); */
}

.dark-stepper .flow-stepper-frame ul li::after {
  background-color: #efefef;
}

.flow-stepper-frame ul li:last-child:after {
  display: none;
}

.flow-stepper-frame ul li.failed::after {
  background-color: #e70f0f;
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;
}

.flow-stepper-frame ul li.completed::after {
  background-color: #32CD32;
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;

}

.flow-stepper-frame ul li.hash-completed::after {
  background-color: none;
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;

}

.flow-stepper-frame ul li.hash-completed .flow-stepper-process {
  background-color: #32CD32;
}

.flow-stepper-frame ul li.hash-failed::after {
  background-color: none;
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;
}

.flow-stepper-frame ul li.hash-failed .flow-stepper-process {
  background-color: #e70f0f;

}

.flow-stepper-frame ul li.hash-failed-1::after {
  background-color: #e70f0f;
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;
}

.flow-stepper-frame ul li.hash-failed-1 .flow-stepper-process {
  background-color: #32CD32;

}

.flow-stepper-frame ul li.completed .flow-stepper-process {
  background-color: #32CD32;
}

.flow-stepper-frame ul li.failed .flow-stepper-process {
  background-color: #e70f0f;
}

.flow-stepper-frame ul li {}

.flow-stepper-frame ul li:last-child .flow-stepper-card {
  align-items: flex-end;
}

.flow-stepper-frame ul li:first-child .flow-stepper-card {
  align-items: flex-start;
}

.flow-stepper-frame ul li:last-child .flow-stepper-card span {
  text-align: end;
}

.flow-stepper-frame ul::after {
  /* content: ' ';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  top: 30px;
  left: 0;
  background: #fff;
  transform-origin: center;
  transform: scale(0.95); */
}

.flow-stepper-card.completed .flow-stepper-process {
  /* border: 1px dashed #fff; */
}

.flow-stepper-frame ul.completed::after {
  background-color: #32CD32;
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;

}

.flow-stepper-frame ul.failed::after {
  background-color: #e70f0f;
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;

}

.flow-stepper-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  font-size: 0.65em;
  font-weight: 600;
  /* background-color: #f3e2c33b; */
  color: var(--quaternary-color);
  border-radius: 10px;

}

.flow-stepper-card.completed {
  color: var(--tertiary-color);
}

.flow-stepper-card .spinner-loader {
  margin: 0 !important;
  border-right-color: var(--primary-color) !important;
  border: 4px solid #6e6a6a29 !important;
}

.flow-stepper-process {
  /* background-color: var(--light-color); */
  background-color: #fff;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.dark-stepper .flow-stepper-process {
  background-color: #efefef;
}

.flow-stepper-card.completed .flow-stepper-process {
  background-color: #32CD32;
}

.flow-stepper-card.failed .flow-stepper-process {
  background-color: #ea0909;
}

.flow-stepper-success.completed .flow-stepper-process {
  background-color: #32CD32;
}

.flow-stepper-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  font-size: 0.75em;
  font-weight: 600;
  color: var(--quaternary-color);
  padding: 1em;
  border-radius: 10px;

}

.flow-stepper-success.completed {
  color: var(--tertiary-color);
}

.flow-stepper-bar {
  background-color: #efefef;
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
}

.flow-loader {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #ffa516 94%, #0000) top/4.5px 4.5px no-repeat,
    conic-gradient(#0000 30%, #ffa516);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4.5px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn)
  }
}

@keyframes wipe-in-right {
  from {
    clip-path: inset(0 100% 0 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}



/* Transaction View Page start */
.transaction-basic-info-wrap {
  background-color: #F9F9F9;
  border-radius: 10px;
  padding: 1.5em;
}

.transaction-basic-info-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-basic-info-head-titles h3 {
  font-size: 1.1em;
  font-family: 800;
  color: var(--tertiary-color);
  margin-bottom: 0.5em;
}

.transaction-basic-info-head-titles p {
  font-size: 0.85em;
  font-family: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
  line-height: 1.4;
}

.transaction-space {
  margin: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.transaction-basic-info-content {}

.transaction-basic-content-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-basic-content-card {
  margin-bottom: 0.8em;
}

.transaction-basic-content-card:last-child {
  margin-bottom: 0em;
}

.transaction-basic-content-card p {
  font-size: 0.85em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
  line-height: 1.4;
}

.transaction-basic-content-card h5 {
  font-size: 0.85em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
  line-height: 1.4;
}

.pay-badge {
  --_backcolor: var(--backcolor, var(--primary-color));
  --_textcolor: var(--color, var(--light-color));
  background-color: var(--_backcolor);
  color: var(--_textcolor);
  padding: 0.4em 1.4em;
  border-radius: 30px;
  text-align: center;
  font-size: 0.8em;
  font-weight: 500;
}

.succes {
  --backcolor: #EBF6E0;
  --color: #5F843A;
}

.denied {
  --backcolor: #ff00000f;
  --color: red;
}

.pending {
  --backcolor: rgb(248, 213, 13, 15%);
  --color: rgb(248, 213, 13);
}

.transaction-tab-info-card {

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transaction-tab-info-card {
  margin-bottom: 0.8em;
}

.transaction-tab-info-card:last-child {
  margin-bottom: 0em;
}

.transaction-tab-info-card p {
  font-size: 0.85em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0;
  line-height: 1.4;
}

.transaction-tab-info-card h4 {
  font-size: 0.85em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
  line-height: 1.4;
}

.copy-view-card {
  display: flex;
  align-items: center;
  gap: 1em;
}

.copy-view-card span {
  word-break: break-all;
  max-width: 399px;
  text-align: right;
}

.transaction-tab-frame {
  padding-top: 2em;
}

.qr-tab-view {
  width: 12em;
  height: 12em;
  object-fit: contain;
}

.transaction-tab-status-card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  background-color: #F9F9F9;
  border-radius: 10px;
  padding: 1em;
}

.transaction-tab-status-wrap {}

.transaction-tab-status-info {
  position: relative;
  font-size: 0.9em;
  font-weight: 600;
  color: var(--tertiary-color);
}

.transaction-tab-status-info::after {
  content: '→';
  position: absolute;
  color: var(--primary-color);
  top: 0;
  right: -25px;
}

.transaction-tab-status-info:last-child:after {
  display: none;
}

.transaction-tab-status-titles {
  font-size: 1em;
  font-weight: 600;
  color: #000;
  margin-bottom: 1em;
}

.bank-tab-card-wrap {
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
}

.bank-tab-card-wrap-info h4 {
  font-size: 0.9em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0.5em;
}

.bank-tab-card-wrap-info p {
  font-size: 0.85em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0em;
  line-height: 1.4;
}

.bank-tab-card-info h5 {
  font-size: 0.95em;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 7px;
  color: var(--tertiary-color);
  margin-bottom: 0;
  word-wrap: break-word;
}

.bank-tab-card-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  border-radius: 10px;
  background-color: #FFFDF6;
}

.bank-tab-card-other-info {
  padding: 1em;
  border-radius: 10px;
  background-color: #F9F9F9;
}

.bank-tab-card-other-info-titles {
  margin-bottom: 1em;
}

.bank-tab-card-other-info-titles h3 {
  font-size: 1em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
  line-height: 1.2;
}

.bank-tab-card-other-wrap p {
  font-size: 0.85em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0em;
  line-height: 1.4;
}

.bank-tab-card-other-wrap h4 {
  font-size: 0.85em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
  text-align: right;
  line-height: 1.5;
  word-break: break-word;
}

.bank-tab-card-other-wrap {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: 300px auto;
  display: grid;
  gap: 1em;
  margin-bottom: 0.8em;
}

.bank-tab-card-other-wrap:last-child {
  margin-bottom: 0em;
}

.bank-tab-info-wrap {
  padding-top: 2em;
}

.transaction-proof-frame {
  text-align: center;
}

.transaction-proof-img {
  background: #fff;
  width: 23em;
  height: auto;
  margin: 0 auto;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.transaction-proof-back {
  padding-bottom: 2em;
}

.transaction-proof-back .overlay {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: center;
}

/* Transaction View Page end */


/* New Stepper style  */
.stepper-card {
  --default-b: lightgrey;
  --default-c: black;
  --active-b: #32CD32;
  --faile: #fe0000;
  --active-c: white;
  --circle: 2.5em;
  /* size of circle */
  --b: 2px;
  /* line thickness */
  --w: 100%;
  display: flex;
  list-style: none;
  justify-content: space-between;
  background:
    linear-gradient(var(--default-b) 0 0) no-repeat 50% calc((var(--circle) - var(--b))/2)/100% var(--b);
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.stepper-icons {
  width: var(--circle);
  height: var(--circle);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--light-color);
  border: 3px solid #f9f0e2;
  z-index: 999;
  position: relative;
}

.stepper-label {
  font-size: calc((var(--circle) - var(--b))/3);
  font-weight: 500;
  color: var(--tertiary-color);
}

.stepper-card .stepper-list {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  position: relative;
  z-index: 0;
}

.stepper-card .stepper-list.active .stepper-icons {
  background-color: var(--active-b);

}

.stepper-card .stepper-list.completed .stepper-icons {
  background-color: var(--active-b);

}

.stepper-card .stepper-list:last-child {
  align-items: flex-end;
}

.stepper-card .stepper-list:first-child {
  align-items: flex-start;
}

.stepper-card .stepper-list.active {
  /* position: relative; */
}

.stepper-card .stepper-list.active::after {
  content: "";
  position: absolute;
  height: var(--b);
  left: calc(90% - var(--circle));
  top: calc((var(--circle) - var(--b))/2);
  width: calc(90% - var(--circle));
  /* background: red; */
  background: var(--active-b);
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;
  z-index: -1;

}

.stepper-card .stepper-list.failed::after {
  content: "";
  position: absolute;
  height: var(--b);
  right: 45%;
  top: calc((var(--circle) - var(--b))/2);
  width: calc((20vw - 4%)/1);
  /* background: red; */
  background: var(--faile);
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-in-right both;
  z-index: -1;

}

/* 
.stepper-card .stepper-list{
text-align: center;
display: grid;
place-items: center;
gap: 5px;
font-family: sans-serif;
position: relative;
}
.stepper-card .stepper-list::before {
  content:  " ";
   display: grid;
  place-content: center;
  aspect-ratio: 1;
  height: var(--circle);
   border: 5px solid #fff; 
  box-sizing: border-box;
  background: var(--active-b);
  color: var(--active-c);
  border-radius: 50%;
  font-family: monospace;
  z-index: 1;
}
.stepper-list.completed{
position: relative;
display: grid;
place-items: center;
}
.stepper-card .stepper-list.active::after {
  content: " ";
  position: absolute;
  height: var(--b);
  right: 100%;
  top: calc((var(--circle) - var(--b))/2);
  width: 100vw;
  background: var(--active-b);
  z-index: 0;
}
.stepper-card .stepper-list.active ~ .stepper-list::before{
  background: var(--default-b);
  color: var(--default-c);
}
*/

.auth-qr-details-swap-frame {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--light-color);
  border-radius: 10px;
  padding: 1.5em;
  margin-bottom: 1em !important;
  position: relative;
  gap: 0em;
}

.auth-qr-details-swap-frame .efi-swap-exchange-info p,
.deposit-amt-details .efi-swap-exchange-info p {
  font-size: 0.85em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0;
}

.auth-qr-details-swap-frame .efi-swap-exchange-info p span:first-child,
.deposit-amt-details .efi-swap-exchange-info p span:first-child {
  font-size: 0.9em;
  font-weight: 600;
  color: var(--quaternary-color);
  margin-bottom: 0;
}

.buy-amount-card .efi-swap-exchange-info p span:first-child {
  font-size: 0.75em;

}

.buy-amount-card .efi-swap-exchange-info p span:last-child {
  font-size: 0.85em;

}

.buy-amount-card .deposit-amt-pay .amt-pay-amt {
  font-size: 0.75em;
  font-weight: 500;
  margin-bottom: 0em;
  color: var(--tertiary-color);
}

.buy-amount-card .deposit-amt-get .amt-pay-amt {
  font-size: 0.75em;
  font-weight: 500;
  margin-bottom: 0em;
  color: var(--tertiary-color);
}

.auth-qr-details-swap-frame .admin-wallet-qr-frame {
  /* margin-left: 10em; */
}

.auth-qr-details-swap-frame .deposit-amt-details {
  grid-area: 1 / 1 / 2 / 3;
}

.auth-qr-details-swap-frame .auth_deposit_qr_scan {
  grid-area: 1 / 3 / 2 / 4;
}

.deposit-amt-details {
  background-color: var(--light-color);
  border-radius: 10px;
  margin-bottom: 1em !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deposit-amt-details-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.deposit-amt-pay {
  display: flex;
  flex-direction: column;
}

.deposit-amt-pay .amt-pay-label {
  font-size: 0.8em;
  font-weight: 300;
  margin-bottom: 0.5em;
  color: var(--quaternary-color);
}

.deposit-amt-pay .amt-pay-amt {
  font-size: 0.85em;
  font-weight: 500;
  margin-bottom: 0em;
  color: var(--tertiary-color);
}

.deposit-amt-get {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.deposit-amt-get .amt-pay-label {
  font-size: 0.8em;
  font-weight: 300;
  margin-bottom: 0.5em;
  color: var(--quaternary-color);
}

.deposit-amt-get .amt-pay-amt {
  font-size: 0.85em;
  font-weight: 500;
  margin-bottom: 0em;
  color: var(--tertiary-color);
}

.wallet-deposit-grid {
  display: flex;
  /* grid-template-columns: repeat(2, 1fr); */
  /* gap: 3.8em; */
  background-color: var(--light-color);
  position: relative;
  z-index: 1;
}

.buy-amt-frame {
  background-color: #f8f9fa;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1.5em;
}

.buy-inr-para {
  max-width: 199px;
}

.buy-inr-para p {
  font-size: 0.7em;
}

.buy-flow-product-sec .wallet-deposit-grid .deposit-amt-details {
  margin-bottom: 0 !important;
}

.wallet-deposit-grid .deposit-amt-details {
  flex: 1 auto;
}

.wallet-deposit-grid .buy-amt-frame {
  flex: 1 auto;

}

.deposit-amt-details {
  padding: 1.5em;
}


/* Business module style  start  */
.business-wrapped {
  padding: 2em 0;
}

.from-space {

  margin: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.business-from-card-titles {
  /* display: flex;
align-items: center;
justify-content: space-between; */
  margin-bottom: 2em;
}

.business-from-card-titles h3 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0.5em;
}

.form-card-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5em;
}

.business-kyc-manual-list {
  padding: 1.5em;
  border-radius: 10px;
  border: 1px solid var(--border-color);
}

.business-kyc-manual-list ul {
  margin-bottom: 0;
}

.business-kyc-manual-list ul .business-kyc-card {
  padding: 1em;
  border-radius: 10px;
  color: var(--tertiary-color);
  font-size: 0.9em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  /* background-color: var(--overlay-bg); */
}

.business-kyc-manual-list ul .business-kyc-card.completed {
  background-color: var(--primary-color);
}

.business-kyc-manual-list ul .business-kyc-card.active {
  background-color: #fec60024;
}

.business-kyc-manual-list ul li {
  margin-bottom: 1em;
}

.business-kyc-manual-list ul li:last-child {
  margin-bottom: 0;
}

.upload-doc-card {
  border: 1px dashed var(--border-color);
  gap: 1.2em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 10px;
}

.onboarding-inner-area {
  gap: 1.2em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 10px;
}

.illustration-avater {
  width: 10em;
  height: auto;
  object-fit: contain;

}

.welcome-onboarding-info-sec h4 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--tertiary-color);

}

.business-auth-footer {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  margin-top: 1.5em;

}

.business-kyc-manual-upload-dropzone-info-sec h4 {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0.5em;
  color: var(--tertiary-color);
}

.business-kyc-manual-upload-dropzone-info-sec h4 {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0.5em;
  color: var(--tertiary-color);
}

.business-kyc-manual-upload-dropzone-info-sec p {
  color: var(--quaternary-color);
  font-size: 0.85em;
  font-weight: 400;
  margin-bottom: 0;
  text-align: center;
}

.business-kyc-manual-upload-dropzone-upload-btn-sec {

  border: 1px solid var(--border-color);
  background-color: var(--secondary-color);
  border-radius: 50px;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 0.85em;
  font-weight: 600;
}

.business-kyc-manual-upload-dropzone-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.business-kyc-manual-uploaded-dropzone-upload-btn-sec {

  display: grid;
  grid-template-columns: 40px auto 40px;
  align-items: center;
  border: 1px solid #E7E8E9;
  background-color: var(--light-color);
  border-radius: 50px;
}

.business-kyc-manual-uploaded-dropzone-upload-middle {
  padding: 0.4em 1em;
  color: var(--tertiary-color);
  font-size: 0.8em;
  font-weight: 400;
  margin-bottom: 0;
}

.business-kyc-manual-uploaded-dropzone-upload-left {
  padding: 0.4em 0.5em;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f1f8ff;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.business-kyc-manual-uploaded-dropzone-upload-right {
  padding: 0.4em 0.5em;
  display: flex;
  background-color: #FFF5F5;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.business-from-titles h3 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0.5em;
}

.business-from-titles p {
  font-size: 0.9em;
  font-weight: 400;
  color: var(--quaternary-color);
  margin-bottom: 0em;
  line-height: 1.8;
}

.business-from-titles {
  margin-bottom: 1.5em;
}

.business-kyc-manual-titles h3 {
  font-size: 1.1em;
  font-weight: 600;
  color: var(--tertiary-color);
  margin-bottom: 0.5em;
}

.business-kyc-manual-titles {
  margin-bottom: 1.5em;
}

.feature-profile-address-grid_three {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1em;
  gap: 0.5em;
}

/* Business module style  end  */


/* Empty Section start  */
.empty-data-wrapped {
  height: 399px;
}

.empty-data-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  height: 100%;
  flex-direction: column;
}

.empty-data-info {
  font-size: 1em;
  font-weight: 600;
  color: var(--tertiary-color);
  line-height: 1.4;
}

.empty-data-img {
  width: 14em;
  height: auto;
  object-fit: contain;
}

.pair-empty-data-img {
  width: 10em;
  height: auto;
  object-fit: contain;
}

.trade-note {
  text-align: center;
  margin-bottom: 1em;
}

.trade-note p {
  font-size: 0.9em;
  font-weight: 400;
  color: red;
  margin-bottom: 0;

}

.conventional-data-wrapped {
  height: calc(100vh - 100px);
}

.conventional-data-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  height: 100%;
  flex-direction: column;
}

.conventional-data-info {
  font-size: 1em;
  font-weight: 600;
  color: var(--tertiary-color);
  line-height: 1.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.conventional-data-img {
  width: 30em;
  height: auto;
  object-fit: contain;
}

/* Empty Section end  */