/* Profile sec start */
.feature-profile-wrapped {
   padding: 2em;
   background: #f7f7f7;
   height: 100%;
   overflow: auto;
   width: 100%;
   /* padding: 1.5em; */
   /* border-radius: 10px; */
   /* min-height: calc(100vh - 0px); */
   /* display: flex;
   align-items: start;
   justify-content: start; */
   /* padding: 2em; */
}

.feature-profile-content-frame {
   display: flex;
   flex-direction: column;
   gap: .5em;
}

.feature-primary-profile-card {
   display: flex;
   flex-direction: column;
   gap: 1em;
   background: var(--secondary-color);
   padding: 1.5em;
   border-radius: 10px;
}

.feature-primary-profile-input {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 1em;
   position: relative;
}

.address-para {
   max-width: 470px;
}

.upload-profile-btn {
   position: absolute;
   top: -0.5em;
   left: 53%;
   border-radius: 50% !important;
   border: 0.75px solid #dde1e6 !important;
   background: var(--secondary-color) !important;
   width: 2.5em;
   height: 2.5em;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 0 !important;
}

.feature-primary-profile-info {
   display: flex;
   flex-direction: column;
   gap: 0.3em;
   text-align: center;
}

.feature-primary-profile-info h3 {
   font-size: .95em;
   font-weight: 500;
   margin-bottom: 0;
   color: var(--tertiary-color);
}

.feature-primary-profile-info p {
   font-size: 0.8em;
   font-weight: 400;
   margin-bottom: 0;
   color: #979ba2;
}

.feature-primary-profile-details {
   background: #F7F7F7;
   padding: 1.5em;
   border-radius: 10px;
   border: 1px solid #00337d24 !important;
}

.feature-primary-profile-details p {

   color: var(--quaternary-color);
   font-size: 0.85em;
   font-weight: 400;
   margin-bottom: 0;
}

.feature-primary-profile-details h4 {
   font-size: .95em !important;
   font-weight: 500 !important;
   margin-bottom: 0.5em;
   color: var(--tertiary-color);
}

.feature-profile-action {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   justify-content: space-between;
   gap: 1em;
}

.feature-second-profile-card {
   background: var(--secondary-color);
   padding: 1.5em;
   border-radius: 10px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 1em;
}

.feature-second-profile-card .nav-pills {
   gap: 1em;
   margin-bottom: 1em;
   box-shadow: 0px 1px 0px 0px #00000012;
}

.feature-second-profile-card .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
   background: none;
   border-bottom: 3px solid var(--Prime, #F9C201);
}

.feature-second-profile-card .nav-pills .nav-link {
   padding: 0;
   padding-bottom: .5em;
   border-radius: initial !important;
   font-size: .95em !important;
   font-weight: 500 !important;
   margin-bottom: 0;
   color: var(--tertiary-color);
}

.feature-profile-address-grid {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   margin-top: 1em;
   gap: 1em;
}

.feature-profile-address-info h3 {
   font-size: .95em;
   font-weight: 500;
   color: var(--tertiary-color);
   margin-bottom: 0.5em;
   text-transform: capitalize;
}

.business-person-titles {
   padding: 1em 0;
   margin-top: 1em;
   margin-bottom: 1.5em;
   border-bottom: 1px solid var(--border-color);

}

.business-person-titles h3 {
   font-size: 1em;
   font-weight: 600;
   color: var(--tertiary-color);
   margin-bottom: 0em;
   text-transform: capitalize;
}

.person-details-box {
   background-color: var(--secondary-color);
   padding: 2em;
   border-radius: 12px;
}

.person-details-box .feature-second-profile-card {
   padding: 0;
}

.person-details-box .feature-second-profile-info {
   margin-bottom: 1.5em;
}

.feature-profile-address-info p {
   font-size: 0.8em;
   font-weight: 400;
   color: var(--tertiary-color);
   margin-bottom: 0em;
}

.feature-second-profile-info h3 {
   font-size: .95em;
   font-weight: 500;
   color: var(--tertiary-color);
   margin-bottom: 0.5em;
}

.feature-second-profile-info p {
   font-size: 0.85em;
   font-weight: 400;
   margin-bottom: 0em;
   color: var(--quaternary-color);
   line-height: 1.7;
}

.feature-second-profile-right {
   display: flex;
   align-items: center;
   gap: 2em;
}


/* Profile sec end */